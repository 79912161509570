import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props={
    isSelected?: boolean;
    width?: string;
    isTop?:boolean;
}

export const SelectOptions = styled.div<Props>`
display:flex;
gap:25px;
flex-direction:column;
width:100%;
max-width:${({width})=>width ?? '300px'};
position:absolute;
border-radius:7px;
${({isTop})=>isTop && 'bottom: 46px'};
min-height:auto;
background:${Color.default};
border:1px solid ${Color.DeelaaRed500};
padding:1.5rem 1rem;
z-index: 2;

`

export const  Option = styled.div<Props>`
display:flex;
gap:10px;
align-items:center;
font-size:.875rem;
p{
color:${({isSelected})=>isSelected ? Color.DeelaaRed500:Color.dark};
font-weight:${({isSelected})=>isSelected ? '700':'400'};
}

`

export const Circle = styled.div<Props>`
height:20px;
width:20px;
display:flex;
align-items:center;
justify-content:center;
border:1px solid ${({isSelected})=>isSelected ? Color.DeelaaRed500:Color.Neutral500};
border-radius:50px;
cursor: pointer;
`
export const InnerCircle = styled.div`
height:10px;
width:10px;
background:${Color.DeelaaRed500};
border-radius:50%;

`