import { Color } from "assets/theme/colors";
import styled from "styled-components/macro";

type storenNavbarProps = {
  bgColor?: string;
  color?: string;
  width?: string;
  gap?: string;
  className?: string;
}

export const StoreNavbarWrapper = styled.div<storenNavbarProps>`
display: flex;
align-items:center;
justify-content:center;
position: sticky;
top: 0;
width:100%;
padding: 0 1rem;
background:${({ bgColor }) => bgColor};
height:80px;
z-index:9999;
h2{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}
@media (max-width: 768px) {
height:60px;
}
`

export const StoreNav = styled.div<storenNavbarProps>`
display: flex;
justify-content:space-between;
align-items:center;
color:${({ color }) => color};
width:100%;
max-width:1200px;
svg{
    cursor: pointer;
}
`

export const StoreNavItem = styled.div<storenNavbarProps>`
  width: ${({ width }) => width};
  display:flex;
  gap:${({ gap }) => gap};
  align-items: center;


  .button{
      background-color: transparent;
      border: none;
      position: relative;
      margin-bottom: -0.5rem;
  }

  svg{
      cursor: pointer;
  }


  @media(max-width:768px){
      display:none;
  }
`

export const MobileNavItem = styled.div<storenNavbarProps>`
  display:none;
  align-items: center;
  width: ${({ width }) => width};


  .button{
      background-color: transparent;
      border: none;
      position: relative;
      margin-bottom: -0.5rem;
      margin-right: 1rem;
  }

  .close-btn {
        background:transparent;
        outline:none;
        border:none;
        margin-left:10px;
        height:100%;
        cursor:pointer;
        padding:0 5px;
  }

   svg{
      cursor: pointer;
  }

  @media(max-width:768px){
      display:flex;
  }

`;


export const StoreAvatar = styled.div<storenNavbarProps>`
background:#8A8A8A;
 display: flex;
 cursor:pointer;
justify-content:center;
align-items:center;
border-radius:50%;
height:35px;
width:35px;

`
export const HambugerMenu = styled.div<storenNavbarProps>`
    display: none ;
@media(max-width:768px){
    display: flex ;
}

`
export const DropDownWrapper = styled.div`
z-index: 999;
height:auto;
width:220px;
background:${Color.default};
position:absolute;
box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius:4px;
top:58px;
padding:1.5rem 0 ;
animation: zoom-in-zoom-out .5s ease ;
@keyframes zoom-in-zoom-out {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }

}


 @media(max-width:768px){
     right:20px;
     top:60px;
  }
`
export const DropDownItem = styled.div`
display: flex;
align-items: center;
gap:15px;
padding:.5rem 1rem ;
cursor:pointer;
&:hover{
background:${Color.Neutral100};
svg{
  color:${Color.DeelaaRed500};
};
}
svg{
  width:20px;
}
`
export const ProfileName = styled.div`

  p{
   white-space: nowrap;
    font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  }
`
export const LogoButton = styled.div`
cursor:pointer;
border:none;
background: transparent;
font-weight: 600;
color: #fff;
max-width:130px ;
img{
height:40px;
object-fit: cover;
}
@media (max-width:768px) {
img{ height: 35px !important;}
}
`

export const CartQuantity = styled.span`
    position: absolute;
    right: -9px;
    top: -9px;
    background-color: ${Color.lightGray200};
    border-radius: 9px;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    justify-content: center;
    width: 20px;
    height: 20px;
    justify-content: center;
    color: #fff;
    align-items: center;

`