import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'


type Props={
    isSet:boolean;
    content:string;
    isActive:boolean;
}


export const Step=styled.div <Props>`
display:flex;
align-items: center; 
justify-content:center;
position:relative;
width:35px; 
background: ${({isSet,isActive})=>isSet && !isActive? `${Color.DeelaaRed500}`:`${Color.default}` };
color:${({isSet,isActive})=>isSet || isActive? ` ${Color.DeelaaRed500}`:`${Color.DeelaaRed100}`};
height:35px;
border-radius:50%;
margin-right:60px;

border: ${({isSet,isActive})=>isSet || isActive? `2.5px solid ${Color.DeelaaRed500}`:`2.5px solid ${Color.DeelaaRed100}`};
@media(max-width:480px){
    margin-right:20px;
}
&::before{
    content: "";
    width:80px; 
    height:1px;
    position:absolute;
    left: 40px;
    background:${({isSet})=>isSet? `${Color.DeelaaRed500}`:`${Color.DeelaaRed100}`};
    @media(max-width:640px){
    width: 70px;
    left: 35px;
    }
    @media(max-width:280px){
    width: 30px;
    left: 30px;
    }


};
&::after{
    content: '${({content})=>content}';
    width:50px; 
    position: absolute;
    text-align: center;
    justify-content: center;
    top: 40px;
    line-height: 1;
    color: ${({isSet})=>isSet? ` ${Color.dark}`:`${Color.Neutral500}`};
    font-size: .6rem;
    font-weight:600;
    @media(max-width:480px){
    width:30px; 

    }
};
&:nth-child(4){
    margin-right:0;
    &::before{
    content: "";
    width:0; 
 }

}
`


export const MultiStepsWrapper= styled.div`
margin: auto;
padding: 2rem 0;
width: 100%;
max-width:450px;

`

export const Steps = styled.div`
display:flex; 
gap:3px; 
justify-content:space-between;
align-items:center;
padding: 0 1rem 3rem 1rem;

`

export const Body= styled.div`
margin: auto;
padding: 2rem 0;
width: 100%;
padding: 0 1rem;

`

