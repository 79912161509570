import styled from 'styled-components/macro'

type Props = {
    direction?: string;
    gap?: string;
    justifyContent?: string
    width?: string
    align?: string;
    margin?:string;
}

export const RowDiv = styled.div<Props>`
width:${({width})=> width ? width : '100%'};
display:flex;
flex-wrap:wrap;
flex-direction:${({direction})=> direction ? direction : 'row'};
align-items:${({align})=> align};
justify-content:${({justifyContent})=> justifyContent? justifyContent : 'start'};
gap:${({gap})=> gap};
margin:${({margin})=> margin};

` 