import styled from "styled-components";
import { Color } from "../../../assets/theme/colors";


type NavProps = {
  bgColor?: string;
  color?:string;
  position?:string;
};

type DropdownProps = {
  show?: boolean;
};

export const Nav = styled.div<NavProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: background-color .5s linear;
  background: ${({ bgColor }) => bgColor};
  height: 5rem;
  z-index: 1;
  position: ${({position})=>position?position:'sticky'};
  top: 0; 
  z-index: 99;
  border-bottom: 1px solid transparent;

  @media(max-width:640px){
    button{
      width: 100px;
      font-size: .8rem;
      height: 2.2rem;

    }
  }
`;
export const NavLinkItem = styled.div`
  &:hover {
    color: ${Color.DeelaaRed500} !important;
  }
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${NavLinkItem} {
    @media (max-width: 768px) {
      display: none;
    }
  }
  img {
    cursor: pointer;
  }
`;

export const NavGroup = styled.div<NavProps>`
  display: flex;
  gap: 2rem;
  align-items: center;
  color:${({color})=>color};
`;

export const DropDown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2rem;
  gap: 0.5rem;
  cursor: pointer;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.5s linear;
  box-shadow: 0px 4px 8px ${Color.lightGray};
  &:hover {
    box-shadow: 0px 6px 10px ${Color.lightGray};
  }
`;

export const DropDownItems = styled.div<DropdownProps>`
  width: 100%;
  height: auto;
  min-width: 230px;
  background: ${Color.default};
  position: absolute;
  opacity: ${({ show }) => (show ? "1" : "0")};
  top: 0;
  right: 0;
  margin-top: 3rem;
  border-radius: 0.8rem;
  box-shadow: 0px 1px 11px ${Color.lightGray};
  padding: 1rem 0;
  max-height: ${({ show }) => (show ? "300px" : "0")};
  transition: max-height 350ms, opacity 650ms;
  overflow: hidden;
  z-index: 9999999;
`;
export const Icon = styled.div`
  svg {
    color: ${Color.darkGray};
  }
`;

export const List = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.8rem 1.5rem;
  &:hover {
    color: ${Color.DeelaaRed500};
    ${Icon} {
      svg {
        color: ${Color.DeelaaRed500};
      }
    }
  }
`;
export const Title = styled.p`
  font-size: 0.95rem;
`;

export const Divider = styled.div`
  border-top: 3px solid ${Color.lightGray};
`;

export const Overlay = styled.div<DropdownProps>`
  position: fixed;
  background: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  top: 80px;
  left: 0;
  transition: opacity 0.3s 0.1s ease;
  opacity:0;
  transform-origin:top;
  max-height:0;
  ${({ show }) => show && `
  transition: opacity  0.3s 0.1s ease;
  opacity: 1;
  max-height:100%;
 
 
  
  ` };
`;
