import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'


export const InputField = styled.div`
width: 80%;
position: relative;
margin:3rem 0 ;
input{
border-radius: 7px;
height: 50px;
width:100%;
padding:1rem ;
outline: none;
border: none;
&::placeholder{
    color:${Color.Neutral300};
    font-size:1rem ;
}

}
button{
    position:absolute;
    height: 40px;
    top: 5px;
    right: 5px;

}
@media(max-width:480px){
    width: 100%; 
}

`