import React from 'react';
import { ModalProps2 } from './type';

import {
  ModalContainer,
  ModalBody,
  ModalFooter,
  Dialog,
  ModalHeader,
  ModalContent,
} from './styles/modal.style';
// let close: React.MouseEventHandler<SVGSVGElement> | undefined
const Modal = ({
  children,
  show,
  centered,
  size,
  split,
  origin,
  overflow,
  fullWidth,
  dialogTop,
}: ModalProps2) => {
  // close = onClose;
  React.useEffect(() => {

    if (show) {
      document.body.style.overflow = 'hidden';
    } else {

      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [show]);
  return (
    <ModalContainer fullWidth={fullWidth} split={split} show={show} centered={centered} size={size}>
      <Dialog dialogTop={dialogTop} show={show} centered={centered} size={size}>
        <ModalContent origin={origin} show={show} overflow={overflow}>
          {children}
        </ModalContent>
      </Dialog>
    </ModalContainer>
  );
};

export default Modal;

Modal.Body = function Body({ children, ...restProps }: ModalProps2) {
  return <ModalBody {...restProps}>{children} </ModalBody>;
};

Modal.Footer = function Footer({ children, ...restProps }: ModalProps2) {
  return <ModalFooter {...restProps}>{children}</ModalFooter>;
};

Modal.Header = function Header({
  children,
  origin,
  justifyContent,
  cancel,
  ...restProps
}: ModalProps2) {
  return (
    <ModalHeader justifyContent={justifyContent} {...restProps}>
      {children}
    </ModalHeader>
  );
};
