import React from "react";
import { IconProps } from "../type";

function FilterIcon({ width, height,color,className}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"16"}
      height={height ? height :"16"}
      fill="none"
      viewBox="0 0 18 18"
      className={className}
    >
      <path
        stroke={color ? color :"#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.5 2.5a1 1 0 011-1h13a1 1 0 011 1v1.92a1 1 0 01-.293.706l-5.247 5.248a1 1 0 00-.293.707v2.086L7.333 16.5v-5.42a1 1 0 00-.293-.706L1.793 5.126A1 1 0 011.5 4.42V2.5z"
      ></path>
    </svg>
  );
}

export default FilterIcon;