
import styled from 'styled-components';

type Props = {
    direction: string;
    bgColor?: string;
    width?:string;
    position?: string;
    padding?:string;
    justifyContent?:string;
    socialPosition?:string;
    minHeight?:string;
    alignItems?:string;
    margin?:string;
    gap?:string;
    flex?:string;
  
}


export const Container = styled.div<Props>`

background: ${({bgColor})=>bgColor};
width: 100%;
border-radius:8px;
padding:${({padding})=>padding};
margin:${({margin})=>margin};
overflow: hidden;
min-height:${({minHeight})=>minHeight};
align-items:${({alignItems})=>alignItems};

`


export const JumbotronItem = styled.div<Props>`
display: flex;
justify-content:${({justifyContent})=>justifyContent};
padding:${({padding})=>padding?padding :'5rem 0'};
gap:${({gap})=>gap};
flex:${({flex})=>flex};

@media(max-width:768px){
padding:2rem 0;
}

`

export const Inner=styled.div<Props>`
display: flex;
flex-direction:${({direction})=> direction};
gap:30px;

@media(max-width:768px){
flex-direction:column;
}
`

export const Pane=styled.div<Props>`
width:${({width})=> width ? width: '45%'};

p{
    line-height:1.7;
    padding-right:2rem;
    padding-top:1rem;
}

@media(max-width:768px){
width:100%;
p{
 padding-right:0;

}
}
@media(max-width:480px){
h1{
    font-size:1.5rem;
};
p{
 font-size:.9rem ;
}

}
` 

export const ImageBox=styled.div<Props>`
position: relative;
height:100%;

&:before{
    content: " ";
    position: absolute;
    height: 98%;
    width: 90%;
    border: 1px solid #C11E22;
    border-radius: 8px;
    z-index:1;
    right:${({position})=>position};
}
.bigImg{
    position: relative;
    padding:1rem;
    z-index:2;
}


.socialImg{
    position: absolute;
    left:${({socialPosition})=>socialPosition};
    z-index:10;
    bottom: -3rem;
   @media(max-width:640px){
    margin-bottom: 2rem;
   }

}


` 

