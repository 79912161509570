import moment from 'moment';

export function validEmail(email: string): RegExpMatchArray | null {
  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(mailformat);
}

export const formatDate = (date: any, type?: string) => {
  return moment(date).format(type ?? 'dddd. MMM Do, YYYY');
};

export const getStatsByStatus = (data: [], name: string) => {
  return data?.find((item: { [key: string]: any }) => item.name === name);
};

export const ratings = (star = 0): Array<number> => {
  return Array.from({ length: star }, (_, i) => i + 1);
};

export const removeTag = (tag: string) => (tags: string[]) => {
  return tags?.filter((t) => t !== tag);
};

const isObject = (obj: any) => {
  return (Object.prototype.toString?.call(obj) === '[object Object]');
}

export const compareObject = (obj1: { [key: string]: any }, obj2: { [key: string]: any }) => {
  if (!isObject(obj1) || !isObject(obj2)) {
    return false;
  }
  let len = null;
  //check if they're of thesame length
  if (Object.keys(obj1).length != Object.keys(obj2).length) {
    return false;
  } else {
    len = Object.keys(obj1).length; //use any length
  }

  let match = 0; //store number of matched properties
  Object.keys(obj1).forEach(i => {
    //check if values with the same keys are equal
    if (obj1[i] === obj2[i]) {
      match++; //increment the variable
    }
  })
  //check if length equals the number of matched properties
  if (match === len) {
    return true;
  }

  return false;
}



export const randomInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const removeHtmlTagsAndEntities = (text: string) => {
  // Replace HTML entities with their corresponding characters
  text = text.replace(/&amp;/g, '&');
  text = text.replace(/&lt;/g, '<');
  text = text.replace(/&gt;/g, '>');
  text = text.replace(/&quot;/g, '"');
  text = text.replace(/&apos;/g, "'");

  // Remove all other HTML entities
  text = text.replace(/&[a-zA-Z0-9#]+;/g, '');

  // Remove HTML tags
  text = text.replace(/<[^>]+>/g, '');

  // Convert any remaining entities to their corresponding characters
  text = text.replace(/&([a-zA-Z0-9]+);/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 10));
  });

  return text;
}

export const capitalize = (value: string): string => {
  if (typeof value !== "string") return "";
  const words = value?.toLocaleLowerCase().trim().split(" ");
  return words
    .map((word) =>
      word?.length === 0 ? word : word[0].toUpperCase() + word.substring(1)
    )
    .join(" ");
};


type BorderType = 'soft' | 'rounded' | 'sharp';

export const getBorderRadius = (type: BorderType): string => {
  switch (type) {
    case 'soft':
      return '10px';
    case 'rounded':
      return '40px';
    case 'sharp':
      return '0';
    default:
      return '0.375rem'; // Fallback in case of an invalid input
  }
};