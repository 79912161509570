import {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useLayoutEffect,
} from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { getStorageItem } from 'utils/local-storage';
import { customerReducer } from './reducers/customerReducer';
import { getStoreId } from 'utils/validation';
import useScript from 'utils/libs/useScript';

import { customerInitialState, initialState } from './state';
import { ContextType, ProviderProps } from './types/type';
import { rootReducer } from './reducers';
import { Action } from './actions';

export const Context = createContext<ContextType | null>(null);

const Provider = ({ children }: ProviderProps) => {
  const PAYMENT_URL = process.env.REACT_APP_REMITA_INLINE;
  useScript(`${PAYMENT_URL}`);

  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [customerState, customerDispatch] = useReducer(
    customerReducer,
    customerInitialState
  );

  const storeId = getStoreId();

  const getVendorStoreInfo = async () => {
    if (storeId) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/vendor/v1/vendors?storeId=${storeId}`
      );
      return data;
    }
  };

  // const getVendorStoreSettings = async () => {
  //   if (storeId) {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/vendor/v1/vendors/store`
  //     );
  //     return data;
  //   }
  // };

  const getVendorContactInfo = async () => {
    if (storeId) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/vendor/v1/vendors/store/contact-details/${storeId}`
      );
      return data;
    }
  };

  const { data: vendorContact } = useQuery(
    'contact-info',
    getVendorContactInfo,
    { keepPreviousData: true }
  );

  const { data, isLoading } = useQuery('store-info', getVendorStoreInfo, {
    keepPreviousData: true,
  });

  // const { data: storeSettings, isLoading: gettingStoreSettings } = useQuery(
  //   'store-settings',
  //   getVendorStoreSettings,
  //   {
  //     keepPreviousData: true,
  //   }
  // );

  const storeTemplate = data?.storeTemplate ?? null;
  // console.log('toreSetting', storeTemplate);

  const user = useMemo(() => {
    return getStorageItem('isAuth');
  }, []);

  const cartItems = useMemo(() => {
    return getStorageItem('cartItems');
  }, []);

  useLayoutEffect(() => {
    dispatch({
      type: Action.LOGIN,
      payload: user,
    });
    dispatch({
      type: Action.VENDOR_BUSINESS,
      payload: { ...data, ...vendorContact?.[0] },
    });

    dispatch({
      type: Action.VENDOR_STORE_DETAILS,
      payload: {
        storeSettings: storeTemplate ? { ...storeTemplate } : null,
        // storeSettings:{
        //   templateId: '2feaf588-da0f-4134-b347-ff4117d5c610',
        //   name: 'EOGUJHN6Qq5iXcY',
        //   backgroundColor: '#CB5040',
        //   fontColor: '#FFFFFF',
        //   fontFamily: "'Galano',sans-serif",
        //   buttonShape: 'soft',
        //   theme: 'one',
        //   logo: {
        //     filename:
        //       'vendor-service/1367d074-f455-40eb-aa0a-8c413b509cd4/04f896e4-0501-40ae-97bb-521ae5292f57.jpg',
        //     mediaType: 'image/jpeg',
        //   },
        //   banner: {
        //     bannerItems: [
        //       {
        //         color: '#FCEC61',
        //         media: {
        //           filename:
        //             'vendor-service/1367d074-f455-40eb-aa0a-8c413b509cd4/c059f686-4da2-4187-ad47-08a04ba990d9.jpg',
        //           mediaType: 'image/jpeg',
        //         },
        //         header: 'TExt',
        //         body: 'buy from me',
        //         enabled: false,
        //       },
        //       {
        //         color: '#2E2E2E',
        //         media: {
        //           filename:
        //             'vendor-service/1367d074-f455-40eb-aa0a-8c413b509cd4/13bad3db-8415-43ab-9c6d-11f3ad8406f0.jpg',
        //           mediaType: 'image/jpeg',
        //         },
        //         header: 'Test 2',
        //         body: 'Buy from us',
        //         enabled: false,
        //       },
        //       {
        //         color: '#2E2E2E',
        //         media: null,
        //         header: 'oopp',
        //         body: 'fjfjfjjfffff',
        //         enabled: false,
        //       },
        //     ],
        //     enabled: false,
        //   },
        //   enabled: true,
        // },
      },
    });

    dispatch({
      type: Action.CART_ITEMS,
      payload: cartItems || [],
    });
  }, [user, data, vendorContact, cartItems]);

  if (isLoading) {
    return null;
  }

  return (
    <Context.Provider
      value={{ state, dispatch, customerState, customerDispatch }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;

export const useAppContext = () => {
  return useContext(Context) as ContextType;
};
