import React from "react";
import { IconProps } from "../type";

const Icon = ({ width, height, color }: IconProps) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width :"19"}
    height={height ? height :"19"}
    fill="none"
    viewBox="0 0 19 19"
  >
    <path
      fill={color ? color :"#1C1C1C"}
      d="M9.497 6.332A3.175 3.175 0 006.33 9.5a3.175 3.175 0 003.168 3.168A3.175 3.175 0 0012.665 9.5a3.175 3.175 0 00-3.168-3.168zM19 9.5c0-1.312.012-2.612-.062-3.921-.074-1.521-.42-2.871-1.533-3.984C16.29.481 14.942.136 13.421.062 12.11-.01 10.81.001 9.5.001 8.188 0 6.888-.011 5.579.062 4.058.136 2.708.483 1.595 1.595.481 2.71.136 4.058.062 5.58-.01 6.89.001 8.19.001 9.5c0 1.31-.012 2.612.061 3.921.074 1.521.421 2.871 1.533 3.984 1.115 1.114 2.463 1.459 3.984 1.532 1.311.074 2.611.062 3.92.062 1.313 0 2.613.012 3.922-.061 1.521-.074 2.871-.421 3.983-1.533 1.115-1.115 1.46-2.463 1.533-3.984.076-1.31.062-2.61.062-3.921zm-9.502 4.874A4.868 4.868 0 014.623 9.5a4.868 4.868 0 014.874-4.874A4.868 4.868 0 0114.372 9.5a4.868 4.868 0 01-4.875 4.874zm5.074-8.81c-.63 0-1.138-.508-1.138-1.138a1.137 1.137 0 111.944.805 1.137 1.137 0 01-.806.333z"
    ></path>
  </svg>
  );
}

export default Icon;
