import styled from 'styled-components/macro';
import { Color } from '../../../assets/theme/colors';

type FormProps = {
  width: string;
  padding: string;
  pr: string;
  pt: string;
  pl: string;
  pb: string;
  footerWidth?: string;
  alignItems: string;
  bgColor?: string;
  gap?: string;
  smDirections?: string;
  xsDirections?: string;
  flexDirection?: string;
  fontSize?: string;
  footerPadding?: string;
  textAlign?: string;
  mdwidth?: string;
  minWidth?: string;
  required?: boolean;
  footerColor?: string;
};
type InputProps = {
  noBorder?: boolean

};
export const FormContainer = styled.form<FormProps>`
  width:100%;
  max-width: ${({ width }) => (width ? width : '100%')};
  padding: ${({ padding }) => padding};
  background: ${({ bgColor }) => bgColor};
  min-width: ${({ minWidth }) => minWidth};
  @media (max-width: 640px) {
    width: 100%;
  }
  @media (max-width: 640px) {
    padding: ${({ padding }) => (padding ? '2rem .2rem' : '')};
  }
`;

export const Input = styled.div<InputProps>`
  position: relative;
  width: 100%;
  input {
    border: ${({ noBorder }) => noBorder ? 'none' : `1px solid ${Color.Neutral500}`};
    border-radius: 7px;
    height: 48px;
    width: 100%;
    padding:${({ noBorder }) => noBorder ? '0 1rem' : '.5rem 1rem'};
    padding: 0.5rem 1rem;
    outline: none;
    &::placeholder {
      color: ${Color.Neutral300};
      font-size: 0.8rem;
    }
  }
  input[type='text']:disabled {
    background: transparent;
    color: ${Color.Neutral500};
/* lightGray8 */
  }
  svg {
    position: absolute;
    top: 14px;
    right: 15px;
    cursor: pointer;
  }
`;

export const Address = styled.div`
  position: relative;
  width: 100%;
  input {
    border: 1px solid ${Color.Neutral500};
    border-radius: 7px;
    height: 48px;
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
    &::placeholder {
      color: ${Color.Neutral300};
      font-size: 0.8rem;
    }
  }
`;
export const Group = styled.div<FormProps>`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : '10px')};
  align-items: ${({ alignItems }) => alignItems};
  padding-top: ${({ pt }) => pt};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};
  padding-bottom: ${({ pb }) => pb};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    gap: ${({ gap }) => (gap ? gap : '10px')};
    flex-direction: ${({ smDirections }) => smDirections};
  }
  @media (max-width: 480px) {
    gap: ${({ gap }) => (gap ? gap : '10px')};
    flex-direction: ${({ smDirections }) => smDirections};
  }
`;

export const Label = styled.label<FormProps>`
  padding-top: ${({ pt }) => pt};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};
  padding-bottom: ${({ pb }) => (pb ? pb : '.5rem')};
  padding: ${({ padding }) => padding};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '.8rem')};
  text-transform: capitalize;
  text-align: ${({ textAlign }) => textAlign};
  span {
    color: ${Color.DeelaaRed900};
    font-size:1rem;
  }
`;
export const Control = styled.div<FormProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  align-items: ${({ alignItems }) => alignItems};
  padding-top: ${({ pt }) => (pt ? pt : '1.2rem')};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};
  padding-bottom: ${({ pb }) => pb};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => (width ? width : '100%')};
  @media (max-width: 585px) {
    width: ${({ mdwidth }) => (mdwidth ? mdwidth : '100%')};
  }
  @media (max-width: 420px) {
    width: 100%;
        flex-direction: ${({ smDirections, flexDirection }) => smDirections || flexDirection || 'column'};
  }
  ${Label}{
   &:after{
    content: "*";
    color: ${Color.DeelaaRed500};
    font-size:1rem;
    opacity:${({ required }) => required ? "1" : "0"};


   }
  }
`;

export const Error = styled.small`
color:${Color.DeelaaRed500};
`;
export const Footer = styled.div<FormProps>`
  display: flex;
  width: ${({ footerWidth }) => footerWidth};
  justify-content: space-between;
  padding: ${({ footerPadding }) => footerPadding || '3rem 0'};
  a {
    color: ${({ footerColor }) => footerColor || Color.DeelaaRed500};
  }
`;
export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 10px 0 0 0;
  .tag {
    background: #ffe0e0;
    display: flex;
    cursor: pointer;
    text-transform: capitalize;
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 30px;
    align-items: center;
    gap: 5px;
  }
`;
export const Select = styled.div`
  width: 100%;
  display: flex;
  position: 'relative';
  select {
    height: 48px;
    border: 1px solid rgba(193, 202, 207, 0.5);
    opacity: 0.5;
    border-radius: 6px;
    padding: 0 1rem;
    outline: none;
    width: 100%;
  }
  select:disabled {
    background: transparent;
    color: ${Color.gray};
  }
`;
export const TextArea = styled.div`

  textarea {
    border: 1px solid ${Color.Neutral500};
    border-radius: 7px;
    padding: 0.5rem 1rem;
    width: 100%;
    outline: none;
    resize: none;
    &::placeholder {
      color: ${Color.Neutral300};
      font-size: 0.8rem;
    }
  }
`;

export const DropDownWrapper = styled.div`
display:flex;
flex-direction: column;
gap:.5rem;
background-color: ${Color.white};
padding: 1rem

`;