import React from 'react';
import { CardDiv, Body, Title, Text, Img, Footer } from './styles/card.style';
import { CardImgProps, CardProps } from './type';

const Card = ({ children, ...restProps }: CardProps) => {
  return <CardDiv {...restProps}>{children}</CardDiv>;
};

export default Card;

Card.Body = function CardBody({ children, ...restProps }: CardProps) {
  return <Body {...restProps}>{children}</Body>;
};
Card.Title = function CardTitle({ children, ...restProps }: CardProps) {
  return <Title {...restProps}>{children}</Title>;
};
Card.Text = function CardText({ children, ...restProps }: CardProps) {
  return <Text {...restProps}>{children}</Text>;
};

Card.Img = function CardImg({ ...restProps }: CardImgProps) {
  return <Img {...restProps} />;
};
Card.Footer = function CardFooter({ children, ...restProps }: CardProps) {
  return <Footer {...restProps}>{children}</Footer>;
};
