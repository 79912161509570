import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'


type props={
    margin:string;
    hideShadow:boolean;
    noLineBreak:boolean;
    justifyContent: string;
    direction:string;
    padding?:string;
    alignItems?:string;
    cursor?:string;
    size?: string;
    showMore?:boolean;
}

export const ListWrapper = styled.div<props>`
display: flex;
justify-content:${({justifyContent})=>justifyContent};
flex-direction:${({direction})=> direction? direction :'column'};
background: #fff;
box-shadow:${({hideShadow})=>hideShadow ? 'none':'4px 7px 14px rgb(0 0 0 / 7%)'};
cursor:${({cursor})=>cursor};
padding:${({padding})=>padding} ;
margin:${({margin})=>margin};



`

export const Item = styled.div<props>`
display: flex;
position: relative;
align-items: center;
font-size:${({size})=>size};
gap: .5rem; ;
padding: ${({padding})=>padding ? padding:'1rem 0'};
flex-direction:${({direction})=> direction};
align-items:${({alignItems})=> alignItems};
justify-content:${({justifyContent})=>justifyContent};
border-bottom: 1px solid ${({noLineBreak})=>noLineBreak ? 'transparent': Color.Neutral300} ;

&:last-of-type{
    border-bottom: 1px solid transparent;
    /* border-bottom: 1px solid ${({noLineBreak})=>noLineBreak ? 'transparent': Color.Neutral300}; */
    &:after{
      content: " ";
      background: ${({showMore})=>showMore ?'linear-gradient(to bottom,  rgba(255,255,255,0.4),  rgba(255,255,255,1))':''};
      position:absolute;
      height:100%;
      width:100%;
      z-index:1
}
}

small{
  color:${Color.Neutral500};
}
h4{
  font-size:.9rem;
}
p{
   font-size:.9rem;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}



`
export const IconBox = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:30px;
height:30px;

`