import React from 'react'
import { RichTextWrapper } from './styles/rich-text.styles'
import { IRichText } from './type'

const RichText = ({ color, text, truncate, fontSize, width }: IRichText) => {
  return (

    <RichTextWrapper
      truncate={truncate}
      fontSize={fontSize}
      color={color}
      width={width}
      dangerouslySetInnerHTML={{ __html: text }}

    />

  )
}

export default RichText