import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_399_11196"
        style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M8 16v8H0V0h24v24h-8v-8H8z"></path>
      </mask>
      <g mask="url(#mask0_399_11196)">
        <path
          stroke="#B4B4B5"
          strokeWidth="1.2"
          d="M15.664 8.956l-.225-.557.225.557A4.9 4.9 0 1117.5 18.4h-9a6.9 6.9 0 116.263-9.8c.158.34.555.495.9.356z"
        ></path>
      </g>
      <path stroke="#B4B4B5" strokeWidth="1.2" d="M12 15v8"></path>
      <path
        stroke="#B4B4B5"
        strokeWidth="1.3"
        d="M13.43 14.35h-2.86L12 12.92l1.43 1.43z"
      ></path>
    </svg>
  );
}

export default UploadIcon;
