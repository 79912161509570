import React from 'react';
import { Button, Stack } from '../';
import { Text } from '../';
import { EmptyBasketIcon, RefundIcon } from '../icons';
import { Color } from 'assets/theme/colors';
import { FallbackProps } from './type';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from './styles/fallback.styles';

const FallBack: React.FC<FallbackProps> = ({
  title,
  refetch,
  showBtn,
  icon,
  buttonWidth,
  btnText,
  description,
  refund,
  routeLink,
  handleRedirect,
}) => {
  const navigate = useNavigate();

  const routeTo = () => {
    navigate(`${routeLink}`);
  };

  return (
    <Wrapper>
      {icon ? (
        <>{icon}</>
      ) : !refund ? (
        <EmptyBasketIcon height="116" width="108" />
      ) : (
        <RefundIcon />
      )}
      <Stack padding="1rem 0" alignItems="center">
        <Text
          as="p"
          width="500px"
          color={Color.dark}
          align="center"
          weight="600"
        >
          {title}
        </Text>
        {description && (
          <Text
            as="p"
            size=".8rem"
            width="500px"
            padding=".3rem 0"
            color={Color.dark}
            align="center"
          >
            {description}
          </Text>
        )}
      </Stack>
      {showBtn && (
        <Button.Group align="center" gap="1rem">
          {!refetch ? (
            <>
              {/* <Button onClick={() => { }} margin={"1rem 0"} borderColor={Color.SoftGray} width={"150px"} color={Color.dark} variant={Color.default}>
                <strong> {'Import'}</strong>
              </Button> */}
              <Button
                onClick={handleRedirect ?? routeTo}
                margin="1rem 0"
                fontSize="0.875rem"
                width={buttonWidth ?? '150px'}
              >
                {btnText || ' Add Product'}
              </Button>
            </>
          ) : (
            <>
              {refetch && (
                <Button
                  onClick={refetch}
                  margin="1rem 0"
                  width={buttonWidth ?? '150px'}
                >
                  Retry
                </Button>
              )}{' '}
            </>
          )}
        </Button.Group>
      )}
    </Wrapper>
  );
};

export default FallBack;
