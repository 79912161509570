import { Color } from 'assets/theme/colors';
import styled from 'styled-components'

type Props = {
  completed: string;
  bgcolor?:string;
  success?:string;
}


export const ContainerStyles = styled.div`
    height: 14px;
    width: 100%;
    background: #e0e0de;
    border-radius: 50px;
    margin: 1px;
`

 export const FillerStyles = styled.div<Props>`
    height: 100%;
    width: ${({completed})=> completed}%;
    border-radius: inherit;
    text-align: right;

    display: flex;
    align-items: center; 
    justify-content: flex-end;
    font-size: 10px;
    padding-right: 1rem;
    background:${({completed})=>completed >='100' ? Color.Success50:'#d50000'};
    transition: width 1s ease-in-out;
  `


  export const LabelStyles = styled.span`
    padding: 5;
    color: white;
    font-weight: bold;
    `
