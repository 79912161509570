import styled from 'styled-components/macro'
import { Color } from 'assets/theme/colors'

type LoaderProps = {
  withBg?:boolean;
  color?:string;
  space?:string;
}

export const LoaderWrapper=styled.div<LoaderProps>`
display:flex;
gap:.5rem;
justify-content:center;
padding:${({ space }) => space};
background:${({withBg})=>withBg ? Color.default : null};
height: ${({withBg})=>withBg ?'300px': 'auto'};
display: flex;
align-items: center;

`

export const LoaderBox = styled.div<LoaderProps>`
  border: 4px solid ${({color})=>color?color:Color.DeelaaRed500};
  border-top: 4px solid ${Color.default};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin .6s linear infinite;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`
export const LoaderContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    min-height: 400Px;



`