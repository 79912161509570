import { formatDate } from 'utils/common';
import {
  amountConverter,
  capitalize,
  formatId,
  formatUnderScore,
  thousandSeparator,
} from 'utils/format-value';
import { StatusProp, TableProps } from './type';

export const transformData = ({ item, name }: TableProps) => {

  if (item && name === 'orders') {

    const {
      contactInfo,
      deliveryMethod,
      orderId,
      status,
      createdOn,
      grandTotalPrice,
    } = item;
    let amount = `₦${amountConverter(grandTotalPrice)}`;
    let customerFullName = `${capitalize(contactInfo?.firstName) || ''} ${capitalize(contactInfo?.lastName) || ''
      } `;
    let ID = `#${formatId(orderId)}`;
    let date = createdOn ? formatDate(createdOn, 'DD-MMM-YYYY, h:mm:ss ') : '';
    let method = capitalize(deliveryMethod);
    let statuses = capitalize(status);
    return {
      ID,
      customerFullName,
      date,
      statuses,
      method,
      amount,
    };
  }

  if (item && name === 'products') {
    const {
      productName,
      sellingPrice,
      totalQuantity,
      status,
      discountPrice,
      createdOn,
      media,
    } = item;
    let amount = `₦${amountConverter(sellingPrice?.min)}`;
    let name = `${capitalize(productName) || ''}`;
    let image = media?.[0]?.filename;
    let date = createdOn ? formatDate(createdOn, 'DD-MMM-YYYY, h:mm:ss ') : '';
    let discount = `₦${amountConverter(discountPrice?.min)}`;
    let statuses = capitalize(status);
    let stock = thousandSeparator(totalQuantity) || '';
    return {
      image,
      name,
      statuses,
      amount,
      discount,
      stock,
      date,
    };
  }
  if (item && name === 'collections-products') {
    const {
      productName,
      sellingPrice,
      totalQuantity,
      status,
      discountPrice,
      media,
    } = item;
    let amount = `₦${amountConverter(sellingPrice?.min)}`;
    let name = `${capitalize(productName) || ''}`;
    let image = media?.[0]?.filename;
    let statuses = capitalize(status);
    let stock = thousandSeparator(totalQuantity) || '';
    return {
      image,
      name,
      statuses,
      amount,
      stock,
    };
  }
  if (item && name === 'collections') {
    const { name, productCount } = item
    return { image: {}, name, productCount }
  }

  if (item && name === 'refunds') {
    const { returnId, createdOn, status, refundMe, item: productItem } = item

    let ID = `#${returnId}`;
    let productName = productItem?.productName;

    let date = createdOn ? formatDate(createdOn, 'DD-MMM-YYYY, h:mm:ss ') : '';
    let type = refundMe ? "Return & Refund" : "Return Only"

    let statuses = status.toLowerCase() === 'accepted' ? capitalize('fulfilled') : capitalize(status);

    return {
      ID, productName, date, type, statuses
    }
  }

  if (item && name === 'payout') {
    const { payoutId, status, paidOn, amount } = item

    let ID = `#${payoutId.substring(0, 8)}`;
    let date = paidOn ? formatDate(paidOn, 'DD-MMM-YYYY, h:mm:ss ') : '';
    let _amount = `₦${amountConverter(amount)}`;
    let statuses = capitalize(status);

    return {
      ID, date, _amount, statuses
    }
  }

  if (item && name === "transactions") {
    const { orderId, transactionId, date, amount, status } = item

    let ID = `#${formatId(orderId)}`;
    let _date = date ? formatDate(date, 'DD-MMM-YYYY, h:mm:ss ') : '';
    let _amount = `₦${amountConverter(amount)}`;
    let statuses = capitalize(status);

    return {
      ID, _date, transactionId, statuses, _amount
    }
  }

  if (item && name === 'cart') {
    const { productName, media, description, itemId, price, quantity, totalPrice, variant } = item;
    const _variant = Object.entries(variant)
    const img = media?.filename || media[0]?.filename;
    return {
      name: { productName, description, image: img, variant: _variant },
      amount: amountConverter(price),
      quantity: { id: itemId, quantity },
      totalPrice: amountConverter(totalPrice) || item?.price,
    };
  }
  if (item && name === 'checkout') {
    const { productName, media, description, image, price, quantity, totalPrice, variant } = item;
    const _variant = Object.entries(variant)

    return {
      name: { productName, description, image: media?.filename || media?.[0]?.filename, variant: _variant },
      amount: amountConverter(price),
      quantity,
      totalPrice: amountConverter(totalPrice),
    };
  }
  if (item && name === 'myOrders') {

    const {
      grandTotalPrice,
      totalQuantity,
      status,
      createdOn,
      item: productDetails,
      orderId
    } = item;

    const { productName, media, variant } = productDetails

    let price = ` ₦‎${amountConverter(grandTotalPrice)}`;
    let statuses = status.toLowerCase() === 'accepted' ? capitalize('fulfilled') : capitalize(status);
    let date = createdOn ? formatDate(createdOn, 'DD-MM-YYYY, h:mm:ss ') : '';

    return {
      name: { productName, image: media?.filename, orderId, variant },
      price,
      quantity: totalQuantity,
      statuses,
      date,
    };
  }
  if (item && name === 'myReturns') {

    const {

      quantity,
      totalPrice,
      refundMe,
      status,
      createdOn,
      item: productDetails,
      orderId
    } = item;

    const { productName, media, variant } = productDetails

    let price = ` ₦‎${amountConverter(totalPrice)}`;
    let statuses = status.toLowerCase() === 'accepted' ? capitalize('fulfilled') : capitalize(status);
    let date = createdOn ? formatDate(createdOn, 'DD-MM-YYYY, h:mm:ss ') : '';
    let refund = refundMe? "yes":"no"

    return {
      name: { productName, image: media?.filename, orderId, variant },
      price,
      quantity,
      statuses,
      refund,
      date,
    };
  }
};

const obj: StatusProp = {
  Live: 'live-product',
  Draft: 'draft-product',
  Processing: 'processing',
  Pending: 'processing',
  Payment_processing: 'paymentprocessing',
  Payment_pending: 'processing',
  Payment_failed: 'paymentfailed',
  Order_processing: 'processing',
  Return_in_progress: 'processing',
  Returned: 'processing',
  Accepted: 'accepted',
  Fulfilled: 'fulfilled',
  Success: 'fulfilled',
  UNPAID: 'unpaid',
  Failed: 'failed',
  Failed_delivery: 'failed',
  Failed_return: 'failed',
  Rejected: 'failed',
  Paid: 'paid',
  Out_for_delivery: 'outfordelivery',
  Ready_for_pickup: 'readyForPickup',
  Delivered: 'delivered',
};
export const activeClass = (data: string) => {
  let status = obj[data as keyof StatusProp];
  return status;
};
