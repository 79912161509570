import React from 'react';
import { IconProps } from '../type';

function InfoIcon2({ width, height }: IconProps) {
    return (
        <svg width={width ?? "13"} height={height ?? "13"} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6109_25874)">
                <circle cx="6.50033" cy="6.5013" r="5.95833" fill="#D9D9D9" />
                <path d="M6.5 3.51953L6.5 7.85286M6.5 9.47786L6.5 8.66536" stroke="white" strokeWidth="0.65" />
            </g>
            <defs>
                <clipPath id="clip0_6109_25874">
                    <rect width="13" height="13" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default InfoIcon2;
