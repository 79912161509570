import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../config/axios-instance';
import { UploadIcon } from '../icons';
import Text from '../text';
import { Color } from '../../assets/theme/colors';
import { UploadBox } from './styles/file-upload-widget';
import { FileWidgetProps } from './type';
import { ImageType, videoType } from 'data/media.data';
import { errorMessage } from 'utils/message';

const FileUploadWidget: React.FC<FileWidgetProps> = ({
  title,
  type = 'image',
  setFile,
  setFileProgress,
  setUpLoadPreview,
  setLoading,
  urlPath,
  keepUploadFileName = false,
}) => {
  const [fileName, setFileName] = useState<any>();
  const [uploaded, setUploaded] = useState<Boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!acceptedFiles) {
      } else {
        setFileName(acceptedFiles);
        let obj = window?.URL?.createObjectURL(acceptedFiles?.['0']);
        setUpLoadPreview?.(obj);
      }
    },

    [fileName]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: type === 'image' ? ImageType : videoType,
  });

  const uploadFile = async () => {
    setLoading?.(true);
    setUploaded(true);
    try {
      if (fileName) {
        const file = new FormData();
        file.append('media', fileName?.['0']);
        const { data } = await axiosInstance.post(`${urlPath}`, file, {
          onUploadProgress: (e) => {
            setFileProgress?.(Math.round((100 * e.loaded) / e.total));
            setLoading?.(false);
          },
        });
        let addfile = fileName?.['0'];

        toast.success('File uploaded successfully');
        if (!keepUploadFileName) {
          setUploaded(false);
        }
        setUpLoadPreview?.('');
        setFile(data, addfile);
      }
    } catch (error: any) {
      // toast.error(error?.response?.data?.message)
      toast.error(error ? errorMessage(error) : '');
      setLoading?.(false);
    }
  };

  useEffect(() => {
    if (fileName?.['0']) {
      uploadFile();
    }

  }, [fileName]);

  let imgFile = fileName?.['0'];

  let displayFileName = imgFile ? imgFile.name : '';

  return (
    <div {...getRootProps()}>
      <UploadBox>
        <UploadIcon />
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text as={'small'} padding={'.5rem 0 0 0'} color={Color.Neutral500}>
            Drop the file here ...
          </Text>
        ) : (
          <Text as={'small'} padding={'.5rem 0 0 0'} color={Color.Neutral500}>
            {uploaded ? displayFileName : title}
          </Text>
        )}
      </UploadBox>
    </div>
  );
};

export default FileUploadWidget;
