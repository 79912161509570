
//add const to enum help the compiler to generate more optimize code
export const enum Color {
    primary = '#ED3237',
    secondary = '#F4B7B5',
    dark = '#000000',
    dark100 = 'rgba(0,0,0,.72)',
    dark200 = '#1C1C1C',
    dark300 = '#151515',
    gray = '#E5E7EB',
    darkGray = '#4B5563',
    darkGray100 = '#737373',
    darkGray200 = '#EAEAEA',
    darkGray300 = '#F0F0F0',
    darkGray900 = "#060606",
    dark700 = '#4F4F4F',
    dark800 = '#0D0D0D',
    lightGray400 = '#C5C5C5',
    lightGray = '#0000000f',
    lightGray200 = '#8A8A8A',
    lightGray600 = '#EEEEEE',
    deepGray = "#F3F3F3",
    deepGray2 = "#505050",
    deepGray3 = '#161616',
    deepGray4 = '#C4C4C4',
    deepGray7 = '#96969',
    deepGray8 = '#DEDEDE',
    gray7 = '#373737',
    gray9 = '#AEAEAE',
    dark400 = '#0F0F0F',
    lightGray500 = '#999999',
    lightgray3 = '#D1D5DB',
    lightGray8 = '#D9D9D9',
    deepGray5 = '#374151',
    gray8 = '#CFCFCF',
    deepGray9 = '#464646',
    white = '#ffffff',

    DeelaaRed10 = '#FFE7E8',
    DeelaaRed50 = 'rgba(251, 233, 233, 0.8)',
    DeelaaRed100 = '#FFC0C2',
    DeelaaRed200 = '#FFDBDB',
    DeelaaRed400 = '#E50A11',
    DeelaaRed500 = '#C11E22',
    DeelaaRed700 = '#7D1517',
    DeelaaRed800 = '#970c10',
    DeelaaRed900 = '#DE0303',
    DeelaRed001 = 'rgba(120, 3, 3, 0.13)',
    DeelaaInActive = '#F4C9CA',
    DeelaBlack200 = '#1E1E1E',

    DeelaaGradient = 'linear-gradient(0deg, #FEE0E0 -12.9%, rgba(255, 255, 255, 0) 71.78%)',
    Neutral = '#DCDCDC',
    Neutral50 = '#FAFAFA',
    Neutral100 = '#F4F4F4',
    Neutral300 = '#E1E1E1',
    Neutral400 = '#B4B4B5',
    Neutral500 = '#8E8E8E',
    Neutral600 = '#676767',
    Neutral700 = '#484848',
    Neutral800 = 'rgba(46,46,46,.7)',
    Neutral900 = 'rgba(88, 88, 88, 0.51)',
    Neutral950 = '#2E2E2E',
    NeutralLight100 = '#CDCDCD',

    BoxShadow = '0px 4px 4px rgba(0, 0, 0, 0.25)',
    GridBoxShadow = '0 0 4px 4px rgb(5 5 5 / 16%)',
    SoftGray = '#E7E7E7',
    Success = '#15803d',
    Success50 = '#35af50',
    Success100 = '#0da2761a',
    Warning = '#ffbc0e1a',
    Warning2 = '#D78100',
    Waring3 = '#FFFBEB',
    Info = '#1e69c11a',

    Processing = '#D28D07',
    Processing100 = '#FFF2D8',

    Green50 = '#F0FDF4',
    Green300 = '#C7FFCD',
    Green200 = '#5BC25F',
    Green400 = 'rgba(3, 120, 50, 0.13)',
    Green500 = '#037832',
    Green600 = '#16A34A',
    Green700 = '#22C55E',
    default = '#FFFFFF',

    Blue = '#0D63A2',
    Blue100 = 'rgba(13, 99, 162, 0.1)',
    Blue200 = '#00A3FF',

    storefrontTheme = '#262D33',
    storefrontTheme03 = "rgba(38, 45, 51, 0.3)",

    Warning200 = "#FAD7A130",
    Warning300 = " #EFAD03",
    Warning400 = "#DC9B34"
}