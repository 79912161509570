import styled from 'styled-components'
import { Color } from "../../../assets/theme/colors"


export const ToggleSwitch = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;

    input[type="checkbox"] {
       display: none;
    }
    .switch {
            position: absolute;
            cursor: pointer;
            background-color: #D1D5DB;
            border-radius: 25px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: background-color 0.2s ease;
            width: 48.38px;
            height: 29.77px;
            :before{
                position: absolute;
                content: "";
                left: 2px;
                top: 2px;
                width: 27px;
                height: 26px;
                background-color: #fff;
                border-radius: 50%;
                transition: transform 0.3s ease;
            }
          }
          input[type="checkbox"]:checked + .switch::before {
            transform: translateX(18px);
            background-color: ${Color.default};
          }

          input[type="checkbox"]:checked + .switch {
            background-color: ${Color.DeelaaRed500};
    }
`