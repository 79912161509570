// import { styles } from 'data/mock.data';
// import React, { SetStateAction,} from 'react';
import Select from 'react-select';
// import { SelectInputProps } from '../@types';
import { colourStyles } from './styles/select-input.styes';



const SelectInput = ({ placeholder, defaultValue, options, value, onChange, styles, placement }: SelectProps) => {


  return (

    <Select
      components={{
        IndicatorSeparator: () => null,
      }}
      classNamePrefix="react-select"
      id="long-value-select"
      instanceId="long-value-select"
      // defaultValue={value}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      options={options}

      styles={styles || colourStyles()}
      menuPortalTarget={document.body}
      menuPlacement={placement ?? "auto"}
      placeholder={< div className="select-placeholder" > {placeholder}</div >}


    />
  );
}

export default SelectInput
