import React from "react";
import { IconProps } from "../type";
import styled from "styled-components";
import { Color } from "../../../assets/theme/colors";
const Svg = styled.svg`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // position: absolute;
  right: 0;
  :hover {
    transform: scale(1.2);
    stroke: ${Color.Neutral100};
  }
`;

function Icon({ width, height, onClick, color }: IconProps) {
  return (
    <Svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "45"}
      height={height ? height : "50"}
      fill="none"
      stroke={color ? color : Color.Neutral500}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-x-circle"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9.4" stroke="#B7B7B7" strokeWidth="1.2" />
      <path d="M16 8L12 12M12 12L8 16M12 12L16 16M12 12L8 8" stroke="#B7B7B7" strokeWidth="1.2" />
    </Svg>
  );
}

export default Icon;
