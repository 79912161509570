import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props = {
    gap?:string;
    color?:string;
}

export const LineBreakWrapper = styled.div<Props>`
width: 100%;
height:1px;
background:${({color})=>color ? color :Color.Neutral400};
margin:${({gap})=>gap};

`