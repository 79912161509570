import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

import { getStorageItem, logOut, setStorage } from 'utils/local-storage';

let baseURL = process.env.REACT_APP_BASE_URL;
let token = getStorageItem('isAuth')
  ? getStorageItem('isAuth')?.access_token
  : null;
let refreshToken = getStorageItem('isAuth')
  ? getStorageItem('isAuth')?.refresh_token
  : null;

export const axiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${token}` }
});

axiosInstance.interceptors.request.use(
  async (req: any) => {
    token = getStorageItem('isAuth')?.access_token ?? null;

    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    const user: any = jwt_decode(token);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;
    //Refresh token if isExpired
    try {
      const res = await axios.get(`${baseURL}/user/v1/auth/token/refresh`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      req.headers.Authorization = res?.data?.access_token;
      //Update localstorage with the new access token and refresh token
      setStorage('isAuth', res?.data, () => { });
      //Continue request with new access token
      req.headers.Authorization = `Bearer ${res?.data?.access_token}`;
    } catch (err) {
      logOut(() => {
        window.location.href = '/login';
      });
    }
    return req;
  },
  (error) => {

    logOut(() => {
      window.location.href = '/login';
    });

    return Promise.reject(error);
  }
);
