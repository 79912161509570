import { IPriceProps } from 'containers/dashboard/vendor-container/inventory/add-product-container/type';
import useProductPrice from 'features/store-front/container/products/widget/useProductPrice';
import moment from 'moment';

export const thousandSeparator = (x: string | number) => {
  let amount = Number(x);
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const amountConverter = (x: string | number) => {
  let amount = Number(x).toFixed(2);
  let currency = amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${currency}`;
};

export const capitalize = (value?: string) => {
  if (typeof value !== 'string') return '';
  const words = value?.toLocaleLowerCase().trim().split(' ');
  return words?.map((word) =>
    word?.length === 0 ? word : word[0].toUpperCase() + word.substring(1)
  )
    .join(' ');
};

export const formatUnderScore = (value?: string | number): string => {
  let data = value?.toString()
  let newvalue = data!?.replaceAll('_', ' ');
  return capitalize(newvalue);
};

export const addDash = (value?: string): string => {
  let newvalue = value!?.replaceAll(' ', '-');
  return newvalue?.toLocaleLowerCase();
};

const addCommas = (num: string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const removeNonNumeric = (num: string) => num.toString().replace(/[^0-9]/g, '');

export const inputNumberSeparator = (num: string) => {
  return addCommas(removeNonNumeric(num));
};

export const formatArrayCommas = (pricing: IPriceProps) => {
  let itemVariant: { [key: string]: any } = {};

  for (let [k, v] of Object.entries(pricing)) {
    itemVariant[k] =
      v !== '' && typeof v !== 'object' ? v?.toString()?.replaceAll(',', '') : v;
  }

  return itemVariant;
};

export const formatDate = (date: any, type?: string) => {
  return moment(date).format(type ?? 'dddd. MMM Do, YYYY');
};

export const getMaxNumber = (array: { [key: string]: any }[], val: string) => {
  return Math.max(
    ...array?.map((o: { [key: string]: any }) =>
      parseFloat(o[val].replace(/,/g, ''))
    )
  );
};
export const calculatePercentage = (min: number, max: number) => {

  const val = Math.round((min * 100) / max);
  return `${val}%`;
};
export const calculatePrice = (
  discount: string | number,
  mainPrice: string | number
) => {
  let amount = Number(mainPrice) - Number(discount);
  return amountConverter(amount);
};
export const sumOfProperty = (array: { [key: string]: any }[], key: string) => {
  const value = array?.reduce((accumulator, object) => {
    return accumulator + object[key];
  }, 0);
  return value
}
export function formatId(value: string): string | undefined {
  let id = value?.split('-');
  let lastIdValue = id.pop();
  if (lastIdValue) {
    return lastIdValue
  }
}

export function findHighestAndLowestPrices(products: any) {
  let highestPriceProduct = null;
  let lowestPriceProduct = null;

  for (const product of products) {
    const discount = product?.discountPrice?.max || product?.discountPrice;
    const actualPrice = product?.sellingPrice?.max || product?.sellingPrice;
    const percentage = calculatePercentage(actualPrice - discount, actualPrice);
    const showDiscount = parseInt(percentage) > 0;
    const isDiscount = product?.discountPrice && showDiscount;

    if (
      !highestPriceProduct ||
      (isDiscount
        ? product.discountPrice > highestPriceProduct.discountPrice ||
        (product.discountPrice === highestPriceProduct.discountPrice &&
          product.sellingPrice > highestPriceProduct.sellingPrice)
        : product.sellingPrice > highestPriceProduct.sellingPrice)
    ) {
      const {
        discountedPrice,
        mainPrice,
        percentage,
        isDiscount,
      } = useProductPrice(product)
      highestPriceProduct = {
        ...product, price: {
          discountedPrice,
          mainPrice,
          percentage,
          isDiscount,
        }
      };;
    }

    if (
      !lowestPriceProduct ||
      (isDiscount
        ? product.discountPrice < lowestPriceProduct.discountPrice ||
        (product.discountPrice === lowestPriceProduct.discountPrice &&
          product.sellingPrice < lowestPriceProduct.sellingPrice)
        : product.sellingPrice < lowestPriceProduct.sellingPrice)
    ) {
      const {
        discountedPrice,
        mainPrice,
        percentage,
        isDiscount,
      } = useProductPrice(product)
      lowestPriceProduct = {
        ...product, price: {
          discountedPrice,
          mainPrice,
          percentage,
          isDiscount,
        }
      };
    }
  }

  return {
    highestPriceProduct,
    lowestPriceProduct,
  };
}


export const convertToWorkingDays = (day?: string) => {
  if (day) {
    const numericValue = parseInt(day, 10);

    if (isNaN(numericValue)) {
      return day;
    }

    const pluralSuffix = numericValue === 1 ? '' : 's';
    const workingDays = `${numericValue} Working day${pluralSuffix}`;

    return workingDays;
  }
}