import React from "react";
import { IconProps } from "../type";

function EmptyBasketIcon({width, height}:IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"121"}
      height={height ? height :"113"}
      fill="none"
      viewBox="0 0 151 143"
    >
      <path
        fill="#C4C4C4"
        d="M32.472 17.286c-3.863 2.146-5.842 6.421-3.954 9.814 1.888 3.392 6.84 4.46 10.704 2.314 3.864-2.146 5.398-6.817 3.51-10.21-1.888-3.392-6.396-4.064-10.26-1.918zM22.417 31.033c-1.744.968-2.687 2.81-1.904 4.215.782 1.407 2.958 1.78 4.702.813 1.743-.968 2.495-2.97 1.713-4.377-.783-1.406-2.768-1.62-4.511-.65z"
        opacity="0.31"
      ></path>
      <path
        fill="#E9E9E9"
        d="M73.204 142.956c20.119.606 41.31-5.011 55.529-19.833 12.211-12.728 17.507-30.933 18.603-48.169 1.1-17.284.317-38.302-10.306-52.753C126.822 8.314 112.641 1.35 95.813.125c-16.27-1.187-32.26 6.297-41.906 16.791-8 8.705-9.19 18.66-20.362 23.953-10.726 5.083-16.025 7-24.388 15.494-15.776 16.023-8.683 41.14 2.645 57.433 12.845 18.474 37.19 28.433 61.402 29.161v-.001z"
      ></path>
      <path
        fill="#141414"
        d="M110.769 87.908H38.077a3.177 3.177 0 01-3.081-2.392l-10.254-40.19a3.155 3.155 0 01.57-2.733 3.16 3.16 0 012.51-1.227h91.336c.962 0 1.86.428 2.467 1.173.607.745.842 1.712.645 2.652l-8.39 40.19a3.19 3.19 0 01-3.112 2.528l.001-.001zm-74.066-2.826c.16.628.725 1.067 1.374 1.067h72.692c.667 0 1.252-.474 1.388-1.127l8.39-40.19c.087-.42-.018-.85-.289-1.183a1.412 1.412 0 00-1.1-.523H27.823c-.442 0-.85.2-1.12.547a1.405 1.405 0 00-.255 1.22l10.255 40.189z"
      ></path>
      <path
        fill="#fff"
        d="M104.616 104.669H51.952a1.723 1.723 0 110-3.444h52.664c2.83 0 5.132-2.3 5.132-5.127a5.135 5.135 0 00-5.132-5.126H41.178a1.723 1.723 0 110-3.445h63.438c4.731 0 8.58 3.846 8.58 8.572 0 4.726-3.849 8.572-8.58 8.572v-.002z"
      ></path>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.2">
        <path
          fill="#894E6A"
          d="M109.638 95.474c.071-.34.11-.692.11-1.053a5.135 5.135 0 00-5.132-5.127H41.178c-.553 0-1.04-.265-1.356-.669a1.702 1.702 0 00-.368 1.053c0 .952.772 1.722 1.724 1.722h63.438a5.138 5.138 0 015.022 4.074zM104.616 102.993H51.952c-.553 0-1.04-.266-1.355-.67a1.703 1.703 0 00-.368 1.053c0 .952.771 1.722 1.723 1.722h52.664c4.731 0 8.58-3.845 8.58-8.572a8.7 8.7 0 00-.071-1.052c-.523 4.23-4.135 7.519-8.508 7.519h-.001z"
        ></path>
      </g>
      <path
        fill="#141414"
        d="M104.616 105.549H51.952a2.607 2.607 0 01-2.604-2.602 2.607 2.607 0 012.604-2.602h52.663a4.254 4.254 0 004.251-4.248 4.253 4.253 0 00-4.251-4.246H41.178a2.606 2.606 0 01-2.605-2.602 2.606 2.606 0 012.605-2.602h63.438c5.216 0 9.461 4.24 9.461 9.45 0 5.212-4.245 9.452-9.461 9.452zm-52.663-3.445a.844.844 0 00-.844.842c0 .464.379.843.844.843h52.663c4.245 0 7.699-3.45 7.699-7.692 0-4.241-3.454-7.691-7.699-7.691H41.178a.843.843 0 100 1.685h63.438c3.315 0 6.012 2.694 6.012 6.006 0 3.312-2.697 6.007-6.012 6.007H51.952h.001z"
      ></path>
      <path
        fill="#C11E22"
        d="M54.173 109.837a6.892 6.892 0 006.896-6.889 6.893 6.893 0 00-6.896-6.89 6.893 6.893 0 00-6.896 6.89 6.892 6.892 0 006.896 6.889z"
      ></path>
      <path
        fill="#894E6A"
        d="M54.173 107.486a6.89 6.89 0 01-6.788-5.715 6.934 6.934 0 00-.108 1.176 6.893 6.893 0 006.896 6.89 6.893 6.893 0 006.896-6.89c0-.401-.041-.792-.107-1.176a6.891 6.891 0 01-6.789 5.715z"
        opacity="0.2"
      ></path>
      <path
        fill="#C11E22"
        d="M97.274 109.837a6.892 6.892 0 006.896-6.889 6.892 6.892 0 00-6.896-6.89 6.893 6.893 0 00-6.895 6.89 6.892 6.892 0 006.895 6.889z"
      ></path>
      <path
        fill="#894E6A"
        d="M97.32 107.486a6.89 6.89 0 01-6.79-5.715 6.934 6.934 0 00-.106 1.176 6.893 6.893 0 006.895 6.89 6.893 6.893 0 006.896-6.89c0-.401-.041-.792-.107-1.176a6.891 6.891 0 01-6.788 5.715z"
        opacity="0.2"
      ></path>
      <path
        fill="#141414"
        d="M54.173 110.716c-4.288 0-7.776-3.485-7.776-7.769 0-4.284 3.488-7.77 7.776-7.77s7.777 3.486 7.777 7.77c0 4.284-3.49 7.769-7.777 7.769zm0-13.78c-3.317 0-6.016 2.696-6.016 6.011s2.7 6.01 6.016 6.01c3.317 0 6.016-2.695 6.016-6.01s-2.699-6.01-6.016-6.01zM97.274 110.716c-4.287 0-7.776-3.485-7.776-7.769 0-4.284 3.489-7.77 7.776-7.77 4.288 0 7.777 3.486 7.777 7.77 0 4.284-3.489 7.769-7.777 7.769zm0-13.78c-3.316 0-6.016 2.696-6.016 6.011s2.699 6.01 6.016 6.01a6.019 6.019 0 006.016-6.01 6.019 6.019 0 00-6.016-6.01z"
      ></path>
      <path
        fill="#fff"
        d="M54.644 104.962a2.066 2.066 0 10-.95-4.023 2.067 2.067 0 10.95 4.023z"
      ></path>
      <path
        fill="#141414"
        d="M54.173 105.894a2.95 2.95 0 01-2.95-2.946 2.951 2.951 0 012.95-2.947 2.951 2.951 0 012.95 2.947 2.95 2.95 0 01-2.95 2.946zm0-4.134a1.189 1.189 0 101.188 1.188 1.19 1.19 0 00-1.188-1.188z"
      ></path>
      <path
        fill="#fff"
        d="M97.64 104.981a2.066 2.066 0 10-.73-4.068 2.066 2.066 0 10.73 4.068z"
      ></path>
      <path
        fill="#141414"
        d="M97.274 105.894a2.95 2.95 0 01-2.949-2.946 2.951 2.951 0 012.95-2.947 2.952 2.952 0 012.949 2.947 2.95 2.95 0 01-2.95 2.946zm0-4.134a1.19 1.19 0 00-1.188 1.188 1.189 1.189 0 002.377 0 1.19 1.19 0 00-1.189-1.188z"
      ></path>
      <path
        fill="#fff"
        d="M116.662 78.991a1.722 1.722 0 01-1.688-2.078l8.699-41.186a6.298 6.298 0 014.267-4.7l3.338-1.052a1.724 1.724 0 012.162 1.125 1.72 1.72 0 01-1.126 2.16l-3.338 1.051a2.849 2.849 0 00-1.93 2.125l-8.699 41.187a1.725 1.725 0 01-1.685 1.367v.001z"
      ></path>
      <path
        fill="#141414"
        d="M116.662 79.871a2.587 2.587 0 01-2.184-1.182 2.585 2.585 0 01-.366-1.958l8.699-41.187a7.182 7.182 0 014.865-5.357l3.337-1.051a2.594 2.594 0 011.986.174 2.583 2.583 0 011.282 1.527 2.59 2.59 0 01-.175 1.984 2.588 2.588 0 01-1.528 1.279l-3.336 1.05a1.97 1.97 0 00-1.334 1.468l-8.699 41.188a2.615 2.615 0 01-2.547 2.065zm15.136-49.096a.855.855 0 00-.255.04l-3.337 1.05a5.423 5.423 0 00-3.672 4.043l-8.698 41.188a.838.838 0 00.652.998.85.85 0 00.999-.651l8.699-41.186a3.732 3.732 0 012.527-2.783l3.338-1.051a.844.844 0 00.137-1.551.837.837 0 00-.389-.096h-.001z"
      ></path>
      <path
        fill="#C11E22"
        d="M133.124 34.052a2.629 2.629 0 01-.745-5.143l14.339-4.379a2.628 2.628 0 111.538 5.028l-14.339 4.38c-.263.08-.531.117-.793.114z"
      ></path>
      <path
        fill="#894E6A"
        d="M150.003 26.276a2.616 2.616 0 00-.765-1.19 2.627 2.627 0 01-1.825 2.087l-14.339 4.379a2.608 2.608 0 01-2.519-.561c-.066.389-.047.798.076 1.201a2.633 2.633 0 003.286 1.746l14.339-4.38a2.628 2.628 0 001.747-3.282z"
        opacity="0.2"
      ></path>
      <path
        fill="#141414"
        d="M133.146 34.933h-.029a3.499 3.499 0 01-3.328-2.484 3.483 3.483 0 01.258-2.672 3.484 3.484 0 012.074-1.708l14.339-4.379a3.489 3.489 0 012.675.258 3.492 3.492 0 011.711 2.072 3.486 3.486 0 01-.258 2.673 3.484 3.484 0 01-2.074 1.708l-14.338 4.379a3.508 3.508 0 01-1.028.154l-.002-.001zm-.015-1.76a1.99 1.99 0 00.528-.078l14.338-4.379c.448-.137.815-.439 1.035-.85.219-.413.265-.886.128-1.333a1.73 1.73 0 00-.853-1.032 1.74 1.74 0 00-1.333-.129l-14.339 4.379a1.74 1.74 0 00-1.034.851 1.735 1.735 0 00-.128 1.332c.225.734.89 1.232 1.658 1.239zM48.853 87.908a.881.881 0 01-.87-.757l-6.338-44.783a.88.88 0 011.744-.247l6.337 44.783a.88.88 0 01-.873 1.003v.001zM61.528 87.909a.88.88 0 01-.877-.818l-3.168-44.783a.88.88 0 011.756-.124l3.168 44.784a.88.88 0 01-.879.94zM74.203 87.908a.88.88 0 01-.88-.88V42.245a.88.88 0 011.76 0v44.783a.88.88 0 01-.88.88zM86.88 87.908c-.02 0-.042 0-.063-.002a.88.88 0 01-.817-.939l3.168-44.783a.88.88 0 011.757.124L87.757 87.09a.88.88 0 01-.877.817zM99.555 87.908a.88.88 0 01-.873-1.003l6.338-44.783a.88.88 0 011.743.247l-6.337 44.784a.881.881 0 01-.871.756z"
      ></path>
      <path
        fill="#141414"
        d="M119.719 52.081H27.883a.88.88 0 110-1.76h91.836a.88.88 0 110 1.76zM117.931 61.038H29.453a.88.88 0 110-1.76h88.478a.88.88 0 110 1.76zM115.496 69.994H32.031a.88.88 0 110-1.76h83.465a.88.88 0 110 1.76zM114.129 78.951H34.005a.88.88 0 110-1.76h80.124a.88.88 0 110 1.76zM120.963 111.866H31.099a.88.88 0 110-1.76h89.864a.88.88 0 010 1.76zM125.205 111.866h-1.071a.88.88 0 110-1.76h1.071a.88.88 0 110 1.76zM27.946 111.866h-2.43a.88.88 0 110-1.76h2.43a.881.881 0 010 1.76z"
      ></path>
    </svg>
  );
}

export default EmptyBasketIcon;