import { decrypt, encrypt } from '../config/secured-data';

export const setStorage = (key: string, value: {}, next: () => void) => {
  if (typeof window !== 'undefined') {
    let user = JSON.stringify(value);
    localStorage.setItem(key, encrypt(user));
  }
  next();
};

export const getStorageItem = (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      const data = localStorage.getItem(key) || '';
      return data && JSON.parse(decrypt(data));
    }
  } catch (error) {
    localStorage.removeItem(key);
  }
};

export const logOut = (next: () => void) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('isAuth');
  }
  next();
};

export const setSkip = (key: string,value:string, next: () => void) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key,value);
  }
  next();
};

export const getSkip = (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      const data = localStorage.getItem(key) || '';
      return data
    }
  } catch (error) {

  }
};
export const removeSkip = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('skip');
  }

};
