import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props = {
    width: string;
}

export const HeroWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: relative;
width:100%;
padding:7rem 0;
background:${Color.default};
/* background:url('/assets/images/deelaa-banner.webp'); */
/* background-position:center center;
background-size: cover; */

`
export const Overlay = styled.div`
height:100%;
width:100%;
background:${Color.dark100};
position:absolute;
`
export const Contents = styled.div`
z-index:1;
margin-top: 2rem;
`

export const Inner = styled.div<Props>`
display: flex;
flex-direction: column;
justify-content: center;
width:${({width})=>width};
text-align: center;

h1{
    font-size:54px; 
    width: 80%;
    text-align: left;
    line-height: 1;
}
p{

    padding:1.2rem 0;
    text-align:left;
    width: 90%;
}
button{
    margin-top:2.5rem;
}


@media(max-width:1024px){
    h1{
    font-size:40px;
   }  
}

@media(max-width:992px){
    h1{
    font-size:35px;
   }  
}
@media(max-width:640px){
    width: 100%;
    h1{
    font-size:30px;
   } 
   p{
    padding:1.2rem 0rem;
    line-height: 1.5;
    letter-spacing: 0;
    } 
}

`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(250px, auto);
  @media(max-width:992px){
    grid-auto-rows: minmax(200px,auto);
    margin-top: 1rem;   
    gap: 10px;

  }
  @media(max-width:480px){
    grid-auto-rows: minmax(150px,auto);
    margin-top: 1rem;   
    gap: 10px;

  }

  .column-one {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1/3;
    width: 100%;
    min-width: 300px; 
    border-radius:5px; 
    box-shadow: ${Color.GridBoxShadow};
    .instagram{
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index:1;
    top:0;
    right: 0;
    padding:1rem;

    }
    @media(max-width:1024px){
        min-width: auto;
        max-width:300px; 
    }
    @media(max-width:640px){
        max-width: 250px;
    }
    @media(max-width:480px){
        max-width: 200px;
        grid-row: 1/3;    
    }
}
.column-two {
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative ;
    width:100%;
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    border-radius:5px; 
    box-shadow: ${Color.GridBoxShadow};
    min-width: 400px;
 .rating{
    position: absolute;
    z-index:1;
    width:35%;
    left: 10px;
    padding:.3rem 1rem;
    box-shadow:0 0 4px 4px rgb(91 91 91 / 9%);
    border-radius:5px; 
    bottom:-.8rem ;
    background:${Color.default} ;
   
    @media(max-width:480px){
    padding: 0.3rem 0.3rem;
  }
    
 }
 @media(max-width:1024px){
    min-width: auto;
}
    @media(max-width:480px){
    max-height: 150px;
  }
}
.column-three {
display:flex;
align-items: center;
justify-content: center;
position:relative;
grid-column: 2/3;
grid-row: 2 / 3;
border-radius:5px; 
box-shadow: ${Color.GridBoxShadow};
 .rating2{
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index:1;
    bottom:0;
    padding:.5rem;
 
 }
 .fb{
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index:1;
    top:0;
    right: 0;
    padding:.5rem;
    width: 30%;
 }

 @media(max-width:480px){
        max-width: 200px;
        max-height: 150px;
    }
}
.column-four {
display: flex;
align-items: center;
justify-content: center;
position:relative;
grid-column: 3/4;
grid-row: 2/3;
border-radius:5px; 
box-shadow: ${Color.GridBoxShadow};
 .twitter{
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index:1;
    top:0;
    right: 0;
    padding:.5rem;

    }
@media(max-width:992px){
 }
 @media(max-width:480px){
        max-width: 200px;
        max-height: 150px;
    }
}
`