import styled from 'styled-components/macro'
import { Color } from '../../../assets/theme/colors'
type sliderProps = {
  justify?: string;
  alignItems?: string;
}
export const Wrapper = styled.div<sliderProps>`
  display: flex;
  align-items: ${({alignItems})=>alignItems||"center"};
  justify-content: ${({justify})=>justify||"justify"};

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: ${Color.storefrontTheme};
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: ${Color.storefrontTheme};
  font-size: 12px;
  margin-top: 20px;
  width: 40%;
  text-align: center;
  padding: 0.3rem 0;
  border: 1px solid ${Color.Neutral300};
  border-radius:5px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: ${Color.storefrontTheme};
  border: none;
  border-radius: 50%;
  /* box-shadow: 0 0 1px 1px #ced4da; */
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}


`