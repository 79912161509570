import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props = {
    active?: boolean;
    showBorder?: boolean;
    bgColor: string

}


export const TabWrapper = styled.div<Props>`
display:flex; 
gap:20px;
align-items: center;
justify-content:space-between;
/* position:relative; */
border-bottom:${({ showBorder }) => showBorder ? `1px solid ${Color.lightGray}` : 'none'};
margin-bottom:2rem;

@media (max-width: 768px) {
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
   ::-webkit-scrollbar { 
    width: 0;
    height: 0;
     }
    .filter{
   position :relative ;
   right:10px;
}
  }

`
export const TabInner = styled.div`
display: flex;
gap:20px;

`
export const Tab = styled.div<Props>`
position: relative;
white-space: nowrap;
transition: color .5s linear;
color: ${({ active }) => active ? Color.dark : Color.Neutral800};
font-weight:${({ active }) => active ? '600' : ''};
padding:1rem 0;
font-size:	0.875rem;
cursor: pointer;
&:before{
    content: "";
    position: absolute;
    transition: border-color .5s linear;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:-5px;
    bottom: 0px;
    border-bottom: ${({ active, bgColor }) => active ? `8px solid ${bgColor ? bgColor : Color.dark}` : 'none'};
    border-radius: 8px;
}

`

export const Body = styled.div``