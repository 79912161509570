import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { useAppContext } from 'context';
import { getStorageItem } from 'utils/local-storage';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, myErrorHandler } from 'components';
// import {
//   IsUserRedirect,
//   ProtectedRoutes,
// } from 'config/auth-user/authenticated';
import {
  IsStoreUserRedirect,
  StoreProtectedRoutes,
} from 'config/auth-user/store-authenticated';
import { Path } from 'constants/route-paths';
import NotFound from 'pages/404';
import ScrollToTop from 'utils/scroll-top';
import { DashboardLayout } from 'containers';
import { FancyLoader, Loader } from 'components';

// Vendor Protected Routes
// const Dashboard = loadable(() => import('pages/vendor/dashboard'), {
//   fallback: <Loader withBg />,
// });
// const AddProduct = loadable(
//   () => import('pages/vendor/dashboard/products/add-product'), {
//   fallback: <Loader withBg />,
// }
// );
// const AllProducts = loadable(
//   () => import('pages/vendor/dashboard/products/all-products'),
//   {
//     fallback: <Loader withBg />,
//   }
// );
// const Orders = loadable(() => import('pages/vendor/dashboard/orders'), {
//   fallback: <Loader withBg />,
// });
// const OrderDetails = loadable(
//   () => import('pages/vendor/dashboard/orders/order-details'), {
//   fallback: <Loader withBg />,
// }
// );
// const Transactions = loadable(
//   () => import('pages/vendor/dashboard/transactions'), {
//   fallback: <Loader withBg />
// }
// );
// const Payout = loadable(
//   () => import('pages/vendor/dashboard/transactions/payout'), {
//   fallback: <Loader withBg />,
// }
// );
//const Reports = loadable(() => import('pages/vendor/dashboard/reports'), {
//   fallback: <Loader withBg />,
// });
// const PayoutDetails = loadable(
//   () => import('pages/vendor/dashboard/transactions/payout/payout-detail')
// );
// const StoreManagement = loadable(
//   () => import('pages/vendor/dashboard/store-management'), {
//   fallback: <Loader withBg />,
// }
// );
// const Refunds = loadable(() => import('pages/vendor/dashboard/refunds'), {
//   fallback: <Loader withBg />,
// });
// const RefundDetails = loadable(() => import('pages/vendor/dashboard/refunds/refund-details'), {
//   fallback: <Loader withBg />,
// });
// const EditProduct = loadable(
//   () => import('pages/vendor/dashboard/products/edit-product'), {
//   fallback: <Loader withBg />,
// }
// );
// const Collections = loadable(() => import('pages/vendor/dashboard/collections'), {
//   fallback: <Loader withBg />,
// })
// const CollectionDetails = loadable(() => import('pages/vendor/dashboard/collections/collection-details'), {
//   fallback: <Loader withBg />,
// })
// const VendorProfile = loadable(() => import('pages/vendor/dashboard/profile'), {
//   fallback: <Loader withBg />,
// });

//Onboarding Routes
// const Login = loadable(() => import('pages/vendor/login'), {
//   fallback: <Loader withBg />,
// });

// const AccountSetup = loadable(() => import('pages/vendor/account-setup'), {
//   fallback: <Loader withBg />,
// });

//Storefront Routes

const StoreLogin = loadable(() => import('pages/store-front/login'), {
  fallback: <FancyLoader />,
});
const StoreSignup = loadable(() => import('pages/store-front/signup'), {
  fallback: <FancyLoader />,
});

const StoreProducts = loadable(() => import('pages/store-front/products'), {
  fallback: <FancyLoader />,
});

const SingleProduct = loadable(
  () => import('pages/store-front/products/single-product'),
  {
    fallback: <FancyLoader />,
  }
);
const ProductCategory = loadable(
  () => import('pages/store-front/products/categories'),
  {
    fallback: <FancyLoader />,
  }
);
const MyStore = loadable(() => import('pages/store-front'), {
  fallback: <FancyLoader />,
});
const Checkout = loadable(() => import('pages/store-front/checkout'), {
  fallback: <FancyLoader />,
});
const DynamicCheckout = loadable(
  () => import('pages/store-front/dynamic-checkout'),
  {
    fallback: <FancyLoader />,
  }
);
const Cart = loadable(() => import('pages/store-front/cart'), {
  fallback: <FancyLoader />,
});
const Messaging = loadable(() => import('pages/store-front/messaging'), {
  fallback: <FancyLoader />,
});
const MyOrders = loadable(() => import('pages/store-front/my-orders'), {
  fallback: <FancyLoader />,
});
const MyOrderDetails = loadable(
  () => import('pages/store-front/my-orders/order-details'),
  {
    fallback: <FancyLoader />,
  }
);
const RequestRefund = loadable(
  () => import('pages/store-front/my-orders/order-details/request-refund'),
  {
    fallback: <FancyLoader />,
  }
);

const TracKOrder = loadable(
  () => import('pages/store-front/my-orders/order-details/track-order'),
  {
    fallback: <FancyLoader />,
  }
);

const ReturnSuccess = loadable(
  () => import('pages/store-front/my-orders/order-details/request-success'),
  {
    fallback: <FancyLoader />,
  }
);
const ReturnPolicy = loadable(
  () => import('pages/store-front/privacy/return-policy'),
  {
    fallback: <FancyLoader />,
  }
);
const ShippingPolicy = loadable(
  () => import('pages/store-front/privacy/shipping-policy'),
  {
    fallback: <FancyLoader />,
  }
);
const TermsandConditions = loadable(
  () => import('pages/store-front/privacy/terms-and-conditions'),
  {
    fallback: <FancyLoader />,
  }
);
const Profile = loadable(() => import('pages/store-front/profile'), {
  fallback: <FancyLoader />,
});
const ForgotPassword = loadable(
  () => import('pages/store-front/forgot-password'),
  {
    fallback: <FancyLoader />,
  }
);

const ChangePassword = loadable(
  () => import('pages/store-front/change-password'),
  {
    fallback: <FancyLoader />,
  }
);

function App() {
  const { state } = useAppContext();
  const user = state.user || getStorageItem('isAuth');

  let routes = (
    <>
      <ScrollToTop />
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
        <Routes>
          <Route path={Path.MY_STORE} element={<MyStore />} />
          <Route
            path={Path.MY_STORE_FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route
            path={Path.MY_STORE_CHANGE_PASSWORD}
            element={<ChangePassword />}
          />
          <Route element={<IsStoreUserRedirect user={user} />}>
            <Route path={Path.MY_STORE_LOGIN} element={<StoreLogin />} />{' '}
          </Route>
          <Route element={<IsStoreUserRedirect user={user} />}>
            <Route path={Path.MY_STORE_SIGNUP} element={<StoreSignup />} />
          </Route>
          <Route
            path={Path.MY_STORE_SINGLE_PRODUCT}
            element={<SingleProduct />}
          />
          <Route path={Path.MY_STORE_PRODUCTS} element={<StoreProducts />} />

          <Route
            path={Path.MY_STORE_RETURN_POLICY}
            element={<ReturnPolicy />}
          />
          <Route
            path={Path.MY_STORE_SHIPPING_POLICY}
            element={<ShippingPolicy />}
          />
          <Route
            path={Path.MY_STORE_TERMS_CONDITION}
            element={<TermsandConditions />}
          />
          <Route path={Path.MY_STORE_CART} element={<Cart />} />
          <Route
            path={Path.MY_STORE_DYNAMIC_CHECKOUT}
            element={<DynamicCheckout />}
          />
          <Route path={Path.MY_STORE_CHECKOUT} element={<Checkout />} />
          <Route element={<StoreProtectedRoutes user={user} />}>
            <Route path={Path.MY_STORE_PROFILE} element={<Profile />} />

            <Route path={Path.MY_STORE_MYORDERS} element={<MyOrders />} />
            <Route
              path={Path.MY_STORE_MYORDER_DETAILS}
              element={<MyOrderDetails />}
            />
            <Route
              path={Path.MY_STORE_REQUEST_FUND}
              element={<RequestRefund />}
            />
            <Route path={Path.MY_STORE_TRACK_RETURN} element={<TracKOrder />} />
            <Route
              path={Path.MY_STORE_RETURN_SUCCESS}
              element={<ReturnSuccess />}
            />

            <Route path={Path.MY_STORE_MESSAGES} element={<Messaging />} />
            <Route
              path={Path.MY_STORE_CATERGORY_DETAILS}
              element={<ProductCategory />}
            />
          </Route>
          {/* replace with not found component */}
          <Route path="*" element={<NotFound user={user} />} />
        </Routes>
      </ErrorBoundary>
    </>
  );

  return routes;
}

export default App;
