import { IconProps } from "../type";

function MyOrderIcon({ width, height, className }: IconProps) {
    return (
        <svg
            width={width ? width : "18"}
            height={height ? height : "18"}
            viewBox="0 0 18 18"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6V15H15V6M3 6L5.25 3M3 6H5.25M15 6L12.75 3M15 6H12.75M5.25 3H12.75M5.25 3V6M12.75 3V6M12.75 6H5.25" stroke="#B5B5B5" strokeWidth="1.2" />
        </svg>


    );
}

export default MyOrderIcon;