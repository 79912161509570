import styled from 'styled-components';
import { Color } from '../../../assets/theme/colors';


export const UploadBox = styled.div`
 display:flex; 
 cursor:pointer; 
 justify-content:center; 
 align-items:center;
 flex-direction:column; 
 border-radius:7px;
 box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
 height:90px;
 border: 1px solid ${Color.Neutral500};
 padding:0 .5rem ;


`


