import { Color } from 'assets/theme/colors'
import styled from 'styled-components/macro'
import { PaginationWrapperProps } from '../type'


export const PaginationWrapper = styled.div<PaginationWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isStore }) => isStore ? "center" : "flex-end"};
  margin-top:2rem;

  .paginate {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    list-style-type: none;
  }
  .page-item {
    color: ${({ isStore }) => isStore ? Color.Neutral950 : Color.dark100};
    background-color: ${({ isStore }) => isStore ? "#ECECEC" : "transparent"};
    font-weight: ${({ isStore }) => isStore ? "400" : "600"};
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    &:hover {
      color: ${Color.DeelaaRed500};
      background:  ${Color.DeelaaRed50};
      border-radius: 50%;
    }
  }
  .page-link {
  }
  .active-page {
    color: ${({ isStore }) => isStore ?  Color.white : Color.DeelaaRed500};
    background:  ${({ isStore }) => isStore ? Color.Neutral950 : Color.DeelaaRed50 };
    display: flex;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .previous-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: ${Color.default};
    border: 1px solid ${Color.default};
    border-radius: 50%;
    svg {
      transform: rotate(180deg);
      path{
        fill:  ${Color.dark};
      }
     }
    font-weight: 600;
    cursor: pointer;
    margin-right: 0.5rem;
    box-shadow: 0px 0.904708px 7.23767px rgba(23, 27, 30, 0.2);
  }
  .next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: ${Color.default};
    border: 1px solid ${Color.default};
    border-radius: 50%;
    box-shadow: 0px 0.904708px 7.23767px rgba(23, 27, 30, 0.2);
    font-weight: 600;
    cursor: pointer;
    margin-left: 0.5rem;
   svg path{
    fill:  ${Color.dark};
   }
  }
  .pagination-disabled a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #f9fafc;
    opacity: 0.5;
    border: 1px solid ${Color.default};
    border-radius: 50%;

    font-weight:  font-weight: ${({ isStore }) => isStore ? "400" : "600"};
    cursor: not-allowed;
    box-shadow: 0px 0.904708px 7.23767px rgba(23, 27, 30, 0.2);
    svg path{
      fill: ${Color.Neutral500};
    }
  }
`
