import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

export const FooterDiv = styled.div`
width:100% ;

`
export const Inner = styled.div`
display: flex;
width: 100%;
justify-content:space-between;
flex-wrap: wrap;
gap:20px ;
padding: 3.5rem 0;
@media(max-width:640px){
justify-content: left;
}
`

export const FooterList = styled.div`
h4{
    padding-bottom:1rem;
}

svg{
    cursor: pointer;
    transition: all 250ms ease-in-out;
&:hover{
    transform: scale(1.2);
}
}
@media(max-width:768px){
flex:0 0 22%;
}

@media(max-width:640px){
&:first-of-type{
flex: 0 0 100%;
}
flex:0 0 30%;
justify-content: left;
}

@media(max-width:480px){
flex:0 0 45%;
}


`

export const FooterListItems  = styled.div`

p{
    padding-bottom:1rem;
}

`
export const FooterLogo = styled.div`

`
export const SocialIcons = styled.div`
display: flex;
gap:30px ;

`

export const CopyRight  = styled.div`

border-top: 1px solid ${Color.Neutral300};
padding:2rem 0;
text-align: center;
`