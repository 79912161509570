import React from "react";
import { IconProps } from "../type";

export const HomeIcon=({ width, height, color,className }: IconProps)=> {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "20"}
    height={height ? height : "20"}
    fill="none"
    viewBox="0 0 20 20"
    className={className}
  >
    <path
      stroke={color ? color :"#000"}
      strokeWidth="1.2"
      d="M1.449 9.4L10 .849 18.552 9.4H17.4v10h-5.3v-7H7.9v7H2.6v-10H1.449z"
    ></path>
  </svg>
   
  );
}

export default HomeIcon


