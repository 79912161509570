import React from "react";
import { IconProps } from "../type";



function Icon({ width, height, color }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : "16"}
            height={height ? height : "13"}
            fill="none"
            viewBox="0 0 16 13"
        >
            <path
                d="M7 1L1.5 6.5M1.5 6.5L7 12M1.5 6.5H16"
                stroke={color ? color : "#262D33"}
                strokeWidth="1.2"
            ></path>
        </svg>
    );
}

export default Icon;
