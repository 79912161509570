import { TabWrapper, Tab, Body, TabInner } from './styles/tab.styles';
import { TabsProps } from './type';

const TabsOrder = ({
  tabFilterOptions,
  hideFilter,
  children,
  ...restProps
}: TabsProps) => {
  return (
    <>
      <TabWrapper {...restProps} showBorder={!hideFilter}>
        <TabInner>{children}</TabInner>
      </TabWrapper>
    </>
  );
};

export default TabsOrder;

TabsOrder.Tab = function TabsTab({ children, ...restProps }: TabsProps) {
  return <Tab {...restProps}>{children}</Tab>;
};
TabsOrder.Body = function TabsTab({ children, ...restProps }: TabsProps) {
  return <Body {...restProps}>{children}</Body>;
};
