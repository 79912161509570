import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props = {
    disabled?: boolean;
    width?: string;
    variant?: string;
    height?: string;
    color?: string;
    background?: string;
    borderColor?: string;
    fontSize?: string,
    noborder?: boolean;
    fontWeight?: string;
    align?: string;
    gap?: string;
    margin?: string;
    borderRadius?: string;
    disabledColor?: string;
    padding?: string;
    hoverColor?: string;
    hoverStyle?: string
    radius?:string
}

export const ButtonContainer = styled.button<Props>`
display:flex;
justify-content:center;
align-items:center;
background:${({ disabled, disabledColor }) => disabled ? disabledColor || Color.DeelaaInActive : ({ variant }) => variant ? variant : Color.DeelaaRed500};
opacity: ${({ disabled }) => disabled ? 0.5 : 1};
cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
width:${({ width }) => width ? width : 'auto'};
padding:${({ padding }) => padding};
height:${({ height }) => height ? height : '3.2rem'};
color: ${({ color }) => color ? color : `${Color.default}`};
border:none;
font-size:${({ fontSize }) => fontSize ? fontSize : '1rem'};
border-radius:${({ borderRadius }) => borderRadius ?? '.45rem'};
transition: background-color .5s linear;
border:${({ disabled, disabledColor }) => disabled ? `1px solid ${disabledColor || Color.DeelaaInActive}` : ({ borderColor, variant, color, noborder }) => color ? `1px solid ${noborder ? 'transparent' : borderColor ? borderColor : color}` : variant ? `1px solid ${variant}` : `1px solid ${Color.DeelaaRed500}`};
gap:.2rem;
font-weight:${({ fontWeight }) => fontWeight};
margin:${({ margin }) => margin};
&:hover {
    text-decoration: ${({ hoverStyle }) => hoverStyle};
background:${({ disabled, color, disabledColor, hoverStyle }) => disabled ? disabledColor || Color.DeelaaInActive : hoverStyle ? "transparent" : color ? color : Color.default} ;
color: ${({ hoverColor, disabled, variant }) => hoverColor ? hoverColor : disabled ? Color.default : variant ? variant : Color.DeelaaRed500};
border: ${({ disabled, variant, disabledColor }) => disabled ? `1px solid ${disabledColor || Color.DeelaaInActive}` : variant ? `1px solid ${variant}` : `1px solid ${Color.DeelaaRed500}`};
font-weight:${({ fontWeight }) => fontWeight ? fontWeight : 500};
};
@media(max-width:640px){
height:2.5rem;
}
`

export const Group = styled.div<Props>`
display: flex;
align-items: center;
justify-content:${({ align }) => align};
width:${({ width }) => width ? width : '100%'};
gap:${({ gap }) => gap ? gap : '.5rem'} ;
flex-wrap: wrap;
margin:${({ margin }) => margin};
`

export const Filter = styled.button<Props>`
display:flex;
justify-content:center;
align-items:center;
background:${({ disabled }) => disabled ? Color.DeelaaInActive : ({ variant }) => variant ? variant : Color.DeelaaRed500};
cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
width:${({ width }) => width ? width : '100%'};
height:${({ height }) => height ? height : '3rem'};
color: ${({ color }) => color ? color : `${Color.default}`};
border:none;
font-size:${({ fontSize }) => fontSize};
border-radius:.4rem;
transition: background-color .5s linear;
border:${({ disabled }) => disabled ? `1px solid ${Color.DeelaaInActive}` : ({ borderColor, variant, color, noborder }) => color ? `1px solid ${noborder ? 'transparent' : borderColor ? borderColor : color}` : variant ? `1px solid ${variant}` : `1px solid ${Color.DeelaaRed500}`};
gap:.8rem;
outline: none;

`