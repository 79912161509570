import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'


type Props = {
    md?:string;
    sm?:string;
    xs?:string;
    xxs?:string;
}

export const CardDiv = styled.div<Props>`
display: flex;
flex-direction:column;
width:100%;
max-width: 270px;
height:auto;
box-shadow:  0px 2px 4px 1px ${Color.lightGray};
border-radius:1rem;
cursor: pointer;
transition:all .5s ease-in-out;

&:hover{
box-shadow:  2px 2px 8px 8px ${Color.lightGray};
}

@media(max-width:992px){
    max-width: ${({md})=>md ? md : '100%'};
}
@media(max-width:768px){
    max-width: ${({sm})=>sm ? sm : '100%'};
}
@media(max-width:640px){
    max-width: ${({xs})=>xs ? xs : '100%'};
}
@media(max-width:360px){
    max-width: ${({xxs})=>xxs};
}

`

export const Body = styled.div`
padding:1rem;

`
export const Footer = styled.div`
display:flex;
justify-content:space-between;
margin:0 1rem;
padding:1rem 0;
border-top: 1px solid ${Color.lightGray};
`
export const Title = styled.h2`
font-size:1.2rem;


`
export const Text = styled.p`
font-size:.9rem;
color:${Color.darkGray};
padding:.5rem 0;

`
export const Img = styled.img`

`