import styled from "styled-components";

type stackProps = {
    width: string;
    height?: string;
    padding: string;
    margin: string;
    minHeight?: string;
    pr: string;
    pt: string;
    pb: string;
    pl: string;
    direction: string;
    alignItems: string;
    alignSelf?: string;
    justifyContent: string;
    gap: string;
    minDirection: string;
    flexWrap?: string;
    bgColor?: string;
    minWidth?: string;
    maxWidth?: string;
    rounded?: string;
    border?: string;
    smWidth?: string;
    smMaxWidth?: string;
    xsWidth?: string;
    xsMaxWidth?: string;
    xsDirection?: string;
    xsAlignItems?: string;
    xsJustifyContent?: string
    smDirection?: string;
    smGap?: string;
    flex?: string;
}

export const StackWrapper = styled.div<stackProps>`
display: flex;
flex-direction:${({ direction }) => direction ? direction : 'column'};
width:${({ width }) => width ? width : '100%'};
height:${({ height }) => height};
min-height:${({ minHeight }) => minHeight};
padding:${({ padding }) => padding};
margin:${({ margin }) => margin};
padding-top:${({ pt }) => pt};
padding-right:${({ pr }) => pr};
padding-bottom:${({ pb }) => pb};
padding-left:${({ pl }) => pl};
align-items:${({ alignItems }) => alignItems};
align-self:${({ alignSelf }) => alignSelf};
justify-content:${({ justifyContent }) => justifyContent};
gap:${({ gap }) => gap};
flex:${({ flex }) => flex};
flex-wrap:${({ flexWrap }) => flexWrap};
background:${({ bgColor }) => bgColor};
min-width:${({ minWidth }) => minWidth};
max-width:${({ maxWidth }) => maxWidth};
border-radius:${({ rounded }) => rounded};
border:${({ border }) => border};

@media(max-width:992px){
   width:${({ smWidth }) => smWidth};
   max-width:${({ smMaxWidth }) => smMaxWidth};
   flex-direction:${({ smDirection }) => smDirection};
}

@media(max-width:768px){
    h1{
        font-size:1.3rem;
    }
    flex-direction:${({ minDirection }) => minDirection};
    gap:${({ smGap }) => smGap};
}

@media(max-width:640px){
   width:${({ xsWidth }) => xsWidth};
    max-width:${({ xsMaxWidth }) => xsMaxWidth};
    flex-direction:${({ xsDirection }) => xsDirection};
    align-items: ${({ xsAlignItems }) => xsAlignItems};
    justify-content: ${({ xsJustifyContent }) => xsJustifyContent};
}
`