export const enum Action {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    REGISTER = 'LOGOUT',
    VENDOR = 'VENDOR',
    VENDOR_BUSINESS = 'VENDOR_BUSINESS',
    ERROR = 'ERROR',
    USERDATA = 'USERDATA',
    ACTIVESIDEBAR = 'ACTIVESIDEBAR',
    IS_TOGGLE = 'IS_TOGGLE',
    SELECT_PERIOD = 'SELECT_PERIOD',
    ADD_FILTER_QUERIES = 'ADD_FILTER_QUERIES',
    REMOVE_FILTER_QUERIES = 'REMOVE_FILTER_QUERIES',
    SELECT_ALL_TABLE_ITEMS = 'SELECT_ALL_TABLE_ITEMS',
    CLEAR_BUSINESS = 'CLEAR_BUSINESS',
    SEARCH = 'SEARCH',
    ADD_PROD_FILTER_QUERIES = 'ADD_PROD_FILTER_QUERIES',
    REMOVE_PROD_FILTER_QUERIES = 'REMOVE_PROD_FILTER_QUERIES',
    CART_ITEMS = 'CART_ITEMS',
    VENDOR_STORE_DETAILS = "VENDOR_STORE_DETAILS:"
}