import { useNavigate } from 'react-router-dom';

import { Button, Stack, Text } from 'components';
import { Container } from 'components/grid-layout';
import { Path } from 'constants/route-paths';

type  AlertProps= {
  background?: string;
  borderColor?: string;
  textColor?: string;
  buttonColor?: string;
  message?: string;
  buttonText?: string;
  showBtn?: boolean;
}

const Alert = ({
  background = '#FFFBEB',
  borderColor = '#FDE68A',
  textColor = '#D78100',
  buttonColor = '#D78100',
  message = 'You will not be able to make sales or receive payment until you complete your KYC.',
  buttonText = 'Complete KYC',
  showBtn = true,
}:AlertProps) => {
  const navigation = useNavigate();
  const handleRoute = () => {
    navigation(Path.BUSINESS_INFORMATION);
  };
  return (
    <div
      style={{
        background: background,
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${borderColor}`,
        borderRadius: '7px',
        color: textColor,
        position: 'sticky',
        top: '80px',
       
        minHeight: '60px',
        width: '100%',
        padding:"0.5rem"
      }}
    >
      <Container>
        <Stack direction="row" justifyContent="space-between">
          <Text as="p">{message}</Text>
          {showBtn && (
            <Button
              variant={buttonColor}
              height="30px"
              fontSize="12px"
              width="100px"
              onClick={handleRoute}
            >
              {buttonText}
            </Button>
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default Alert;
