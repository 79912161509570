import { calculatePercentage } from 'utils/format-value';

const useProductPrice = (product: { [key: string]: any }) => {
  const discount = product?.discountPrice?.max ?? (product?.discountPrice || 0);
  const actualPrice =
    product?.sellingPrice?.max ?? (product?.sellingPrice || 0);

  const percentage = calculatePercentage(actualPrice - discount, actualPrice);
  const showDiscount = parseInt(percentage) > 0;
  const isDiscount = product?.discountPrice && showDiscount;

  return {
    discountedPrice: discount,
    mainPrice: actualPrice,
    percentage,
    isDiscount,
  };
};

export default useProductPrice;
