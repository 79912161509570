import styled from "styled-components/macro";
import { Color } from "../../../assets/theme/colors";



type HorizontalPosition = 'left' | 'center' | 'right'
type VerticalPosition = 'top' | 'center' | 'bottom'

type props = {
  lg?: boolean;
  size?: string;
  centered?: boolean;
  split?: boolean;
  show?: boolean;
  overflow?: string;
  justifyContent?: string;
  origin?: Exclude<`${HorizontalPosition} ${VerticalPosition}`, 'center center'> | 'center' | 'top'
  fullWidth?: boolean
  dialogTop?: string
};



export const ModalContainer = styled.div<props>`
 
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  justify-content: center;
  top: 0;
  left: 0;
  width:${({ split, fullWidth }) => split && !fullWidth ? 'calc(100% - 15rem)' : '100%'};
  margin-left:${({ split, fullWidth }) => split && !fullWidth ? '15rem' : '0'};
  height: 100vh;
  overflow-x: hidden;
  background: ${Color.Neutral900};
  pointer-events: ${({ show }) => show ? 'auto' : 'none'};
  opacity: ${({ show }) => show ? "1" : "0"};
  transition: opacity 200ms 400ms ease;
  z-index: 1;
  padding: 5px;
  @media(max-width:992px) {
    margin-left:0;
    width: 100%;
  }
  
`;
export const ModalBody = styled.div`
  position: relative;

  padding: 1rem;
`;


export const ModalContent = styled.div<props>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  opacity:0;
  transform:scale(0);
  transform-origin:${({ origin }) => origin ? origin : 'center'};
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  border-radius: 10px;
  ${({ show }) => show && `
  transition: opacity 200ms 300ms ease, transform 250ms 500ms ease;
  transform: scale(1);
  opacity: 1;
  ` };
    overflow: ${({ overflow }) => overflow};
`;
export const Dialog = styled.div<props>`
  position: relative;
  width: 100%;
  margin: 0.5rem;
    top:  ${({ dialogTop }) => dialogTop};
  pointer-events: none;
  display: ${({ centered }) => centered && "flex"};
  align-items: ${({ centered }) => centered && "center"};
  max-width: ${({ lg, size }) => (lg ? "800px" : size ? size : "300px")};
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;


`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  padding: 1rem 0;
  border-top: 1px solid ${Color.lightGray};
`;

export const ModalHeader = styled.div<props>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'space-between'};
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  padding: 1.1rem;
`;
