import React from 'react';

function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <g stroke="#C11E22" strokeWidth="0.85" clipPath="url(#clip0_3482_19693)">
        <circle cx="8.5" cy="8.501" r="7.367"></circle>
        <path d="M8.5 4.604v1.063m-1.77 1.77h.77a1 1 0 011 1v2.25a1 1 0 001 1h.77"></path>
      </g>
      <defs>
        <clipPath id="clip0_3482_19693">
          <path fill="#fff" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InfoIcon;
