import React from 'react'
import { LoaderWrapper } from './styles/fancy-loader.styles'

const FancyLoader = ({size}:{size?:string}) => {
  return (
    <LoaderWrapper size={size}>
        <span className="loader"></span>
    </LoaderWrapper>
  )
}

export default FancyLoader 