import { Color } from 'assets/theme/colors'
import styled from 'styled-components/macro'


type Props = {
    width?: string;
    isShown?: boolean;
}


export const Container = styled.div<Props>`
display:flex;
flex-direction:column;
position: relative;
width:100%;
gap: 10px;
max-width: ${({ width }) => width ?? '700px'};
margin: auto;
margin-top:1rem;
;
`

export const Inner = styled.div`
width:100%;
`
export const TitleWrap = styled.div<Props>`
display: flex;
gap: 20px;
align-items: center;
justify-content:space-between;
height: 65px;
padding-top: 1rem;
width:100%;
text-transform:capitalize;
color: ${Color.dark};
line-height: normal;
padding: 0 1rem;
font-weight: 600;
background:${Color.Neutral100};
cursor: pointer;
svg{
    transition:.5s;
    transform:${({ isShown }) => isShown ? `` : `rotate(180deg)`};
}
@media (max-width:600px){
    font-size: 1rem;
}
`

export const Content = styled.div<Props>`
color: #5d5d5d;
width:100%;
font-size:.875rem;
overflow: hidden;
max-height: ${({ isShown }) => isShown ? '1400px' : '0'};
opacity: ${({ isShown }) => isShown ? '1' : '0'};
transition: max-height .5s ease;
`
export const CheckBox = styled.div`
width: 20px;
height: 20px;
border-radius: 50%;
border: 1px solid ${Color.Neutral500};
`