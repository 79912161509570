import { IconProps } from '../type';

function SortIcon({ width, height, color, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '20'}
      height={height ? height : '20'}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        stroke={color ? color : '#373737'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M2.5 3.333h10.833M2.5 6.666H10M2.5 10h5m3.333 0l3.334-3.334m0 0L17.5 10m-3.333-3.334v10"
      ></path>
    </svg>
  );
}
export default SortIcon;
