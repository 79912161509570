import styled from "styled-components";
import { Color } from "../../../assets/theme/colors";

type Props = {
  show?: boolean;
  isChecked?: boolean;
}

export const FormTableWrapper = styled.div`
  position: relative;
  @media (max-width: 768px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;
export const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background: #fff;
    color: #373737;
    height: 65px;
    tr {
      text-align: left;
      font-size: .9rem;
      border-bottom: 1px solid ${Color.Neutral300};
      th {
        padding: 1rem;
        white-space: nowrap;
        font-weight: 700;
        
      }
      td {
        padding: 1rem;      
        white-space: nowrap;
        
        svg{
          cursor: pointer;
        }
      }
    }
  }
  tbody{

    tr {
    border-bottom: 1px solid ${Color.Neutral300};
    height: 65px;
    color: #373737;
    
    td {
      white-space: nowrap;
      font-size: 1rem;
      padding: .5rem 1rem;
      .live-product{
          display:flex;
          align-items: center;
          justify-content: center;
          width:70px;
          height:25px;
          background:${Color.Green300};
          border-radius:20px;
          font-size:.9rem;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.dark};
            margin-right:.5rem;
          }
        }
        .draft-product{
          display:flex;
          align-items: center;
          justify-content: center;
          width:70px;
          height:25px;
          font-size:.9rem;
          background:${Color.Neutral100};
          border-radius:20px;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.dark};
            margin-right:.5rem;
          }
        }
        svg{
          cursor: pointer;
        }
    }
  }
  }
`;

export const TableFilterBox = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
padding-bottom: 2rem;
@media(max-width:480px){
    flex-direction: column;
    gap: 10px;
}
`

export const TableCheckBox = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid ${({isChecked})=>isChecked ? Color.DeelaaRed500:'#ababab'};
  background:${({isChecked})=>isChecked ? Color.DeelaaRed500:'transparent'};
  border-radius: 4px;
  cursor: pointer;
`;

export const TableFilterInner = styled.div`
input{
  width:350px;
}

@media(max-width:640px){
  width: 100%;
  input{
  width:100%;
}
}

`
export const PopUpWrapper= styled.div<Props>`
right: 1rem;
padding:.5rem;
font-size:.85rem; 
position:absolute;
width:160px;
opacity: ${({show})=>show ? '1' : '0'};
max-height:${({show})=>show ?'400px':'0'}; 
background:${Color.default};
overflow: hidden;
top:16.1rem;
transition: 0.5s;
transform: ${({show})=>show?'translateY(0)':'translateY(50%)'};
`
export const PopUpStack = styled.div`
padding: .5rem 1rem;
p{
  padding: .5rem 0;
  cursor: pointer;
}
`
