import styled from "styled-components/macro";

type Props = {
    fluid?: boolean,
    bgColor?: string,
    justifyContent: string;
    height?: string;
    borderRadius: Boolean,
    align: string,
    bgImage: string;
    margin: string,
    padding: string,
    minHeight: string,
    bgSize: string,
    bgImg: string,
    bgImgPosition: string,
    gap?: string
    smGap?: string
    position: string
    bl?: string
}

export const ContainerDiv = styled.div<Props>`
display:flex;
flex-direction:column;
width:100%;
max-width:${({ fluid }) => fluid ? '100%' : '1200px'};
padding:${({ padding }) => padding ? padding : '0 0.75rem'};
background:${({ bgColor, bgImg }) => bgColor ? bgColor : bgImg ? `url(${bgImg})` : ''};
justify-content:${({ justifyContent }) => justifyContent};
height:${({ height }) => height};
min-height:${({ minHeight }) => minHeight};
align-items: ${({ align }) => align};
gap: ${({ gap }) => gap};
border-radius: ${({ borderRadius }) => borderRadius && '10px'};
background-repeat:no-repeat;
background-image:${({ bgImage }) => bgImage};
position:${({ position }) => position};
margin:${({ margin }) => margin};
position: relative;
background-size: ${({ bgSize }) => bgSize};
background-position: ${({ bgImgPosition }) => bgImgPosition};
border-left: ${({ bl }) => bl};
@media(max-width:420px){
    h3{
    font-size:1rem;
    }
}
@media(max-width:768px){
    gap: ${({ smGap }) => smGap};
}
`