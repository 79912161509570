import React from 'react'
import SearchIcon from '../icons/search-icon'
import { SearchWrapper } from './styles/search.styles'

type SearchProps={
  iconPosition?:'left' | 'right';
}&
React.ComponentProps<'input'>

const Search = ({iconPosition='left',...restProps}:SearchProps) => {
  return (
    <SearchWrapper position={iconPosition}>
        <SearchIcon />
        <input type="search" {...restProps} />
    </SearchWrapper>
  )
}

export default Search