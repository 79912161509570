import React from "react";
import { IconProps } from "../type";



function Icon({ width, height, color }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : "16"}
            height={height ? height : "13"}
            fill="none"
            viewBox="0 0 16 13"
        >
            <path
                d="M9 1L14.5 6.5M14.5 6.5L9 12M14.5 6.5H0"
                stroke={color ? color : "#262D33"}
                strokeWidth="1.2"
            ></path>
        </svg>
    );
}

export default Icon;
