import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Text from "../../text";

import { BsCheck } from "react-icons/bs";
// import { selectedDataType } from "components/table/type";

import { Color } from "assets/theme/colors";

import {
  CheckBox,
  MultiSelectWrapper,
  SelectOptions,
  Option,
} from "./styles/multi-filter.styles";
import { TableFilterInner } from "components/table/styles/table.styles";
import Button from "components/button";
import { useAppContext } from "context";
import { Action } from "context/actions";
import { formatUnderScore } from "utils/format-value";


const FilterSelect = ({ options }: { options?: any[] }) => {

  //TODO REFACTOR
  const { state: { filterQueries }, dispatch } = useAppContext()
  const [show, setShow] = useState(false);

  const addCheckedProducts = (item: {}) => {
    dispatch({ type: Action.ADD_FILTER_QUERIES, payload: item })
    setShow(false);
  };
  const removeCheckedProducts = (item: any) => {
    dispatch({ type: Action.REMOVE_FILTER_QUERIES, payload: item })
    setShow(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setShow(false)}>
      <TableFilterInner>
        <Button.Filter
          height={"40px"}
          width={"150px"}
          onClick={() => setShow(!show)}
        >
          Filter
        </Button.Filter>
        <MultiSelectWrapper>
          {show && (
            <SelectOptions>
              {options?.map((item: { [key: string]: any }, i: number) => (
                <Option key={i}>
                  {filterQueries?.some((p: { [key: string]: any }) => p?.id === item?.id) ? (
                    <CheckBox
                      isSelected
                      onClick={() => removeCheckedProducts(item)}
                    >
                      <BsCheck color={Color.default} />
                    </CheckBox>
                  ) : (
                    <CheckBox
                      onClick={() => addCheckedProducts(item)}
                    ></CheckBox>
                  )}
                  {filterQueries?.some((p: { [key: string]: any }) => p?.id === item?.id) ? (
                    <Text as="p" color={Color.DeelaaRed500} weight="600">
                      {" "}
                      {formatUnderScore(item?.value)}
                    </Text>
                  ) : (
                    <Text as="p" > {formatUnderScore(item?.value)}</Text>
                  )}
                </Option>
              ))}
            </SelectOptions>
          )}
        </MultiSelectWrapper>
      </TableFilterInner>
    </OutsideClickHandler >
  );
};

export default FilterSelect;
