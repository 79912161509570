import React from "react";
import { IconProps } from "../type";

function RateIcon({ width, height, color,className}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"15"}
      height={height ? height :"14"}
      className={className}
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        fill={color?color:"#E50A11"}
        d="M6.5 0l1.46 4.491h4.722l-3.82 2.776 1.459 4.492L6.5 8.983l-3.82 2.776 1.459-4.492-3.82-2.776H5.04L6.5 0z"
      ></path>
    </svg>
  );
}

export default RateIcon;