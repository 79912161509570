import { Navigate, Outlet } from 'react-router-dom'
import jwt_decode from 'jwt-decode';
import { Role } from 'constants/user-role';
import { Path } from 'constants/route-paths';


type routeTypes = {
  user: any
} & React.ComponentProps<'div'>

export const IsStoreUserRedirect = ({ user }: routeTypes) => {
  return !user ? <Outlet /> : <Navigate to={Path.MY_STORE_PRODUCTS} replace />
}

export const StoreProtectedRoutes = ({ user }: routeTypes) => {
  let userRole;
  if (user) {
    let decodeToken = user?.access_token && jwt_decode(user?.access_token)
    userRole = decodeToken?.roles?.[0]
  }

  return user && (userRole === Role.BUYER || userRole === Role.SELLER) ? <Outlet /> : <Navigate to={Path.MY_STORE_LOGIN} replace />
}