import { Color } from '../../assets/theme/colors';

export const initialState = {
  user: null,
  userInfo: null,
  isCollapsed: false,
  error: null,
  isLoading:true,
  filterQueries: [],
  vendorDetails: null,
  vendorBusiness: null,
  vendorStoreDetails: null,
  productFilterQueries: [],
  userOtp: null,
  toggleSidebar: false,
  selectedValue: null,
  show: 0,
  isSelectAll: false,
  cartItems: []
};

export const customerInitialState = {
  theme: {
    bgColor: Color.storefrontTheme,
    color: Color.default,
  },
};
