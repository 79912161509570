import Search from 'components/search'
import { TableFilterInner } from 'components/table/styles/table.styles'
import Filter from '../filter'
import { TabWrapper, Tab, Body, TabInner } from './styles/tab.styles'
import { TabsProps } from './type'

const Tabs = ({ hideSearch = true, tabFilterOptions, direction, showBtn, searchTerm, handleSearch, hideFilter, children, ...restProps }: TabsProps) => {

  return (
    <>{hideSearch && (
      <TableFilterInner >
        <Search placeholder={"Search"} onChange={handleSearch} value={searchTerm} />
      </TableFilterInner>
    )}
      <TabWrapper {...restProps} showBorder={!hideFilter}>
        <TabInner>
          {children}
        </TabInner>
        <Filter direction={direction} tablefilterOptions={tabFilterOptions} hideFilter={hideFilter} showBtn={showBtn} hideSearch={hideSearch} pb={'0'} />
      </TabWrapper>

    </>
  )
}

export default Tabs

Tabs.Tab = function TabsTab({ children, ...restProps }: TabsProps) {
  return <Tab {...restProps}>{children}</Tab>
}
Tabs.Body = function TabsTab({ children, ...restProps }: TabsProps) {
  return <Body {...restProps}>{children}</Body>
}