import React from "react";
import { IconProps } from "../type";

export const CartIcon=({ width, height, color,className }: IconProps)=> {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "24"}
        height={height ? height : "24"}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
        >
        <path
        stroke={color ? color :"#000"}
        strokeWidth="1.2"
        d="M8.4 20a1.4 1.4 0 11-2.8 0 1.4 1.4 0 012.8 0zm10 0a1.4 1.4 0 11-2.8 0 1.4 1.4 0 012.8 0zM1 3h2.5l1 2m0 0h15.328a.1.1 0 01.087.15L16 12H8l-.5-1m-3-6l3 6M19 16H6.618a1 1 0 01-.894-1.447L7.5 11"
        ></path>
        </svg>
  );
}

export default CartIcon




