import styled from 'styled-components'


export const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 2rem;
    svg{
        margin:1.5rem 0 ;
    }
    p{
        text-align:center;
    }
    .close{
        margin-top:1rem;
    }

`