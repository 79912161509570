import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useQuery } from "react-query";
// import { getMapResource } from "../../../api";
import List from "../../list";
import { Address } from "../styles/form.style";
import { addressProps } from "../type";

export default function FormAddress({onChange,address,onAutoComplete,...restProps}:addressProps){
    
    const [autoComplete,] = useState<any[]>([]);
    const [isSelected, setIsSelected] = useState(false)
   
    const autoCompleteAddress=(address:string)=>{
        // return getMapResource('https://maps.googleapis.com/maps/api/place',address)
        return {}
    }
    
    const  {data:addressess} = useQuery(['mapLocation',address],()=>autoCompleteAddress(address))
   

    useEffect(() => {
        if(addressess){
            // setAutoAddress(addressess?.response?.predictions)       
        }
    },[addressess])
    
    return (
        <div style={{position:'relative'}}>
        <Address onClick={()=>setIsSelected(true)}>
               <input onChange={onChange} {...restProps}  />
         </Address>

         {/* Autocomplete list */}
         <OutsideClickHandler  onOutsideClick={() => {setIsSelected(false)}}>
         {isSelected && (
           <div style={{position:'absolute',zIndex:1}}>
           {autoComplete?.map((addrs,i)=>(
               <List key={i} onClick={()=>{onAutoComplete?.(addrs);setIsSelected(false)}} cursor={'pointer'}>
                  <List.Item size={'.9rem'} padding={'1rem'}>
                  {addrs?.description}
                  </List.Item>
               </List>
             ))}
         </div>
         )}
         </OutsideClickHandler>      
        </div>
    )
}
