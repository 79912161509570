import { Color } from 'assets/theme/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
display: flex;
height: 100%;
width: 100%;
justify-content:center;
align-items: center;
flex-direction: column;
background:${Color.default};
padding:2rem 0;


`