import { Color } from '../../../assets/theme/colors';
import styled from 'styled-components'

export const Div = styled.div`
display:flex;
justify-content:center;
width:100%;
margin:auto;
min-height:100vh;
background:${Color.default};

`

