import { MultiStepsWrapper,Steps, Step,Body } from './styles/multi-steps.styles'
import { multiStepsProps } from './type'

const Multi = ({children,...restProps}:multiStepsProps) => {
  return (
    <MultiStepsWrapper {...restProps}>
        {children}
    </MultiStepsWrapper>
  )
}

export default Multi

Multi.Steps = function MultiSteps({children,...restProps}:multiStepsProps){
  return <Steps {...restProps}>{children}</Steps>
}

Multi.Step = function MultiStep({children,...restProps}:multiStepsProps){
   return <Step {...restProps}>{children}</Step>
}

Multi.Body = function MultiBody({children,...restProps}:multiStepsProps){
  return <Body {...restProps}>{children}</Body>
}