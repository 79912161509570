import React from 'react'
import { LoaderBox, LoaderWrapper,LoaderContainer } from './styles/loader.styles'
import Text from '../text'
import { ILoaderProps, LoaderProps } from './type'

const Loader = ({ text, withBg, color, space }: ILoaderProps) => {
  return (
    <LoaderWrapper withBg={withBg} space={space}>
     {text && (<Text as={'p'}>{text}</Text>)} <LoaderBox color={color}/>
   </LoaderWrapper>
  )
}

export default Loader

Loader.Container = function LoaderWrapper({children,...restProps}:LoaderProps){
  return <LoaderContainer {...restProps}>{children}</LoaderContainer>
}