import React from "react";
import { IconProps } from "../type";


const UserIcon=({width,height, color}:IconProps)=>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"24"}
      height={height? height:"24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color || "#ffffff"}
        d="M3 22L2.42471 21.8296C2.37094 22.0111 2.40598 22.2073 2.51924 22.359C2.6325 22.5107 2.8107 22.6 3 22.6V22ZM21 22V22.6C21.1893 22.6 21.3675 22.5107 21.4808 22.359C21.594 22.2073 21.6291 22.0111 21.5753 21.8296L21 22ZM16.4 7C16.4 9.43005 14.4301 11.4 12 11.4V12.6C15.0928 12.6 17.6 10.0928 17.6 7H16.4ZM12 11.4C9.56995 11.4 7.6 9.43005 7.6 7H6.4C6.4 10.0928 8.90721 12.6 12 12.6V11.4ZM7.6 7C7.6 4.56995 9.56995 2.6 12 2.6V1.4C8.90721 1.4 6.4 3.90721 6.4 7H7.6ZM12 2.6C14.4301 2.6 16.4 4.56995 16.4 7H17.6C17.6 3.90721 15.0928 1.4 12 1.4V2.6ZM3.57529 22.1704C4.70965 18.3412 8.0696 15.6 12 15.6V14.4C7.48397 14.4 3.69406 17.5446 2.42471 21.8296L3.57529 22.1704ZM12 15.6C15.9304 15.6 19.2903 18.3412 20.4247 22.1704L21.5753 21.8296C20.3059 17.5446 16.516 14.4 12 14.4V15.6ZM3 22.6H21V21.4H3V22.6Z" ></path>
    </svg>
  );
}

export default UserIcon;