import { ErrorIcon, SuccessIcon, WarningIcon } from '../icons';
import { NotificationProps } from './type';
import Text from '../text';
import { DialogWrapper } from './styles/notification-dialog-styles';
import { Color } from '../../assets/theme/colors';
import Button from '../button';
import Modal from '../modal';

const NotificationDialog = ({
  status,
  title,
  subtitle,
  size,
  actionLabel,
  confirmActionLabel,
  handleConfirmation,
  loading,
  message,
  successIcon,
  show,
  setShow,
  fullWidth,
  variant,
}: NotificationProps) => {
  let Icon;
  if (status === 'success') {
    Icon = <SuccessIcon />;
  } else if (status === 'warning' && !successIcon) {
    Icon = <WarningIcon />;
  } else if (status === 'warning' && successIcon) {
    Icon = <SuccessIcon />;
  } else if (status === 'error') {
    Icon = <ErrorIcon />;
  }
  return (
    <Modal fullWidth={fullWidth} show={show} centered size={size} split={true} origin={'center top'}>
      <DialogWrapper>
        <Text as={'h2'}>
          {title
            ? title
            : status === 'success'
              ? 'Awesome!'
              : status === 'error'
                ? 'Oh Snap!'
                : ''}
        </Text>

        {Icon}
        {subtitle ? (
          <Text as="p" weight="600">
            {subtitle}
          </Text>
        ) : (
          ''
        )}
        <Text as="p">{message && message}</Text>

        {status !== 'warning' && (
          <Button.Group align="center">
            <Button
              variant={Color.default}
              width="150px"
              fontSize="1rem"
              className="close"
              noborder
              color={status === 'success' ? Color.Success : Color.DeelaaRed500}
              onClick={handleConfirmation ?? setShow}
            >
              {actionLabel ?? 'Close'}
            </Button>
          </Button.Group>
        )}
        {status === 'warning' && (
          <Button.Group align="center" gap="2rem" margin="0.8rem 0">
            <Button
              variant={variant || Color.DeelaaRed500}
              width="100px"
              fontSize="1rem"
              className="close"
              noborder
              color={Color.default}
              onClick={() => handleConfirmation?.()}
              loading={loading}
              disabled={loading}
            >
              {actionLabel || 'Delete'}
            </Button>
            <Button
              variant={Color.Neutral300}
              width="100px"
              fontSize="1rem"
              className="close"
              noborder
              color={Color.dark}
              onClick={setShow}
            >
              {confirmActionLabel ?? 'Cancel'}
            </Button>
          </Button.Group>
        )}
      </DialogWrapper>
    </Modal>
  );
};

export default NotificationDialog;
