import React from 'react'
import { FormContainer, Input, Group, Control, Label, Tags, Error, Footer, Select, TextArea } from './styles/form.style'
import { FormProps, InputProps, SelectProps } from './type'
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import FormAddress from './address-input';
import { BiCheck } from 'react-icons/bi';
import { Color } from 'assets/theme/colors';


const Form = ({ children, ...restProps }: FormProps) => {
  return (
    <FormContainer {...restProps}>
      {children}
    </FormContainer>
  )
}

export default Form

Form.Group = function FormGroup({ children, ...restProps }: FormProps) {
  return <Group {...restProps}>{children}</Group>
}
Form.Control = function FormControl({ children, ...restProps }: FormProps) {
  return <Control {...restProps}>{children}</Control>
}

const FormInput = React.forwardRef(({ onChange, showText, noBorder, showPasswordIcon, isValid, handleTogglePassword, ...restProps }: InputProps, ref: React.ForwardedRef<null>) => {


  return <Input noBorder={noBorder}>
    <input onChange={onChange} {...restProps} ref={ref} />
    {showPasswordIcon && (
      <div onClick={() => handleTogglePassword?.((prev) => !prev)}>
        {showText ? <AiOutlineEye size={20} /> :
          <AiOutlineEyeInvisible size={20} />}
      </div>
    )}
    {isValid && <BiCheck size={25} color={Color.Green600} />}
  </Input>
})


Form.Input = FormInput

Form.TextArea = function FormTextArea({ ...restProps }: React.ComponentProps<'textarea'>) {

  return <TextArea>
    <textarea {...restProps}>

    </textarea>
  </TextArea>
}

Form.Address = FormAddress;

Form.Label = function FormLabel({ children, ...restProps }: FormProps) {

  return <Label {...restProps}>{children}</Label>
}

Form.Error = function FormError({ children, ...restProps }: FormProps) {
  return <Error {...restProps}>{children}</Error>
}

Form.Footer = function FormFooter({ children, ...restProps }: FormProps) {
  return <Footer {...restProps}>{children}</Footer>
}

Form.Tags = function FormTags({ children, ...restProps }: FormProps) {
  return <Tags {...restProps}>{children}</Tags>
}

Form.Select = function FormSelect({
  children,
  onChange,
  options,
  ...restProps
}: SelectProps) {
  return (
    <Select>
      <select onChange={onChange} {...restProps}>
        {options?.map((option, i) => (
          <option key={i} disabled={option?.disabled} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </select>
    </Select>
  )
}