function SignOutIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="#B5B5B5"
          fillRule="evenodd"
          d="M9.002.037A9.042 9.042 0 018.96 0H3.18C2.261.187 1.442.525.83 1.225.294 1.839.01 2.537.009 3.32c-.009 3.76-.012 7.52 0 11.279.005 1.72 1.205 3.027 3.013 3.342.038.006.072.022.107.038L3.18 18h5.694l.016-.023c.009-.016.018-.031.032-.04.245-.146.369-.347.269-.61-.105-.275-.355-.362-.653-.362-.492 0-.983-.004-1.475-.008a76.912 76.912 0 00-3.269.02c-1.603.055-2.714-1.156-2.698-2.502.03-2.627.025-5.254.019-7.881-.002-1.038-.004-2.076-.004-3.113 0-1.463 1.029-2.438 2.599-2.444 1.595-.007 3.191-.006 4.786 0 .338.002.644-.029.781-.364.118-.29-.08-.465-.274-.636zM15.02 7.47l1.096 1.01h-3.19l-7.791.001H5.07c-.123-.001-.247-.002-.362.026-.305.073-.46.264-.422.562.035.279.222.422.515.447.081.008.163.006.245.005l.1-.001H16.132l-.742.681c-.62.569-1.2 1.101-1.777 1.638-.31.288-.334.576-.09.802.254.234.54.213.861-.084 1.12-1.034 2.238-2.07 3.353-3.109.359-.335.35-.568-.023-.916-.506-.474-1.015-.944-1.523-1.415l-.458-.423-.337-.313a83.26 83.26 0 00-1.1-1.01c-.246-.218-.523-.22-.766 0-.247.223-.225.474-.01.712.05.054.105.104.16.154l.051.046 1.29 1.187z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }
  
  export default SignOutIcon;