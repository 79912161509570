
import { Item, ListWrapper,IconBox } from './styles/list.styles'
import { ListProps } from './type'

const List = ({children, ...restProps}:ListProps) => {
  return (
    <ListWrapper {...restProps}>
        {children}
    </ListWrapper>
  )
}

export default List

List.Item = function ListItem({children,...restProps}:ListProps){
  return <Item {...restProps}>{children}</Item>
}
List.IconBox = function ListIconBox({children,...restProps}:ListProps){
  return <IconBox {...restProps}>{children}</IconBox>
}