
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneTextInput = ({...restProps}) => {
  return (
    <PhoneInput
    countryCodeEditable={false}
    {...restProps}
    country={'ng'} 
    
  />
  ) 
}

export default PhoneTextInput