export const errorMessage = (err: any) => {
  let error;

  if (err?.message === 'Invalid token specified') {

  }
  else if (err?.message === 'Network Error') {
    error = 'Please check your network connection';
  } else if (err?.response?.data?.message) {
    error = err?.response?.data?.message;
  } else if (err?.status === 500) {
    error = 'Something went wrong, please try again!';
  } else {
    error = 'Something went wrong, please try again!';
  }

  return error;
};

export const errorStatus = (err: any) => {
  let error;

  if (err?.response?.status === 401) {
    error = '401';
  } else if (err?.response?.status === 409) {
    error = '409'
  } else {
    error = '500';
  }
  return error;
};
