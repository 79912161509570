import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props={
    isSelected?: boolean;
}

export const MultiSelectWrapper=styled.div`
position:relative;
`

export const MutiSelectButton =styled.div`
display:flex;
cursor:pointer;
align-items:center;
justify-content:space-between;
min-width:180px;
height:45px; 
font-size:.85rem;
border: 1px solid ${Color.Neutral600};
padding:0 .5rem;
border-radius:7px;
`

export const SelectOptions = styled.div`

display:flex;
gap:15px;
flex-direction:column;
position:relative;
width:200px;
z-index:2;
position:absolute;
border-radius:7px;
font-size:.85rem;
min-height:auto;
background:${Color.default};
padding:1.5rem 1rem 1.5rem 1rem;
box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 10%);

`

export const  Option = styled.div`
display:flex;
gap:10px;
align-items:center;

`

export const CheckBox = styled.div<Props>`
height:20px;
width:20px;
display:flex;
align-items:center;
justify-content:center;
border-radius: 4px;
border:1px solid ${({isSelected})=>isSelected ? Color.DeelaaRed500:'#ababab'};
background:${({isSelected})=>isSelected?Color.DeelaaRed500:''};
cursor: pointer;
`
export const InnerBox = styled.div`
background:${Color.DeelaaRed500};


`