import { Color } from 'assets/theme/colors';
import styled from 'styled-components/macro';

export const DatePickerWrapper = styled.div<DateProps>`
  display: flex;
  position: relative;
  input {
    ::placeholder {
      color: ${Color.DeelaBlack200};
    }
    border: 1px solid ${({ borderColor }) => borderColor || '#D9D9D9'};
    height: ${({ height }) => height || '40px'};
    width: ${({ width }) => width || '150px'};
    border-radius: 8px;
    padding: ${({ padding }) => padding || '0.3rem'};
    outline: none;
    animation: fadeIn 1s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  svg {
    position: absolute;
    right: ${({ iconRight }) => iconRight || '8px'};
    top: ${({ iconTop }) => iconTop || '8px'};
  }
`;
