import React from "react";
import { IconProps } from "../type";

function Location2Icon({width, height, color}:IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"16"}
      height={height ? height :"16"}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#848F9F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 8.5a2 2 0 100-4 2 2 0 000 4z"
      ></path>
      <path
        stroke="#848F9F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 6.5c0 4.5-5 8-5 8s-5-3.5-5-8a5 5 0 1110 0v0z"
      ></path>
    </svg>
  );
}

export default Location2Icon;