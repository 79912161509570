import React from 'react'
import { HeroWrapper,Contents, Inner, Grid } from './styles/hero-banner-styles'
import { HeroProps } from './type'

const HeroBanner = ({children, ...restProps}:HeroProps) => {
  return (
    <HeroWrapper {...restProps}>
        <Contents>
        {children}
        </Contents>
    </HeroWrapper>
  )
}

HeroBanner.Inner = function HeroBannerInner({children,...restProps}:HeroProps) {
   return <Inner {...restProps}>{children}</Inner>
}

HeroBanner.Grid = function HeroBannerGrid({children,...restProps}:HeroProps) {
  return <Grid {...restProps}>{children}</Grid>
}

export default HeroBanner

