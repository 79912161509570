
import { Color } from 'assets/theme/colors';
import { useAppContext } from 'context';
import { Action } from 'context/actions';
import { BsCheck } from 'react-icons/bs';
import { SortIcon } from '../../icons';
import { TableCheckBox } from '../styles/table.styles';
import { selectedDataType } from '../type';

 type HeaderProps ={
  headers?: string[];
  hideChecked?:boolean;
  hideSortIcon?:boolean;
  tableData?: selectedDataType[] | {}[]
 }

const TableHeader = ({headers,hideChecked,hideSortIcon}:HeaderProps) => {

  const {state:{isSelectAll}, dispatch} = useAppContext();



  return ( 
        <thead>
           <tr>
            {!hideChecked &&( 
              <td>
              <TableCheckBox isChecked={isSelectAll} onClick={() => dispatch({type:Action.SELECT_ALL_TABLE_ITEMS})}>
                     {isSelectAll && <BsCheck color={Color.default} size={20}/>} 
                </TableCheckBox>
             </td>
            )}
            
            {headers?.map((header, i) => (
            <th key={i}>{header}</th>
            ))}
            {!hideSortIcon &&(
              <td>
              <SortIcon  />
           </td>
            )}
        </tr>
    </thead>
  )
}

export default TableHeader