export const enum Path {
  REGISTER = '/auth/register',
  LOGIN = '/auth/login',
  PRICING = '/pricing',
  DASHBOARD = '/vendor/dashboard',
  REVIEW_INFORMATION = '/vendor/account-setup?info=review-information',
  BUSINESS_INFORMATION = '/vendor/account-setup?info=business-information',
  BUSINESS_EDIT = '/vendor/account-setup?info=edit-information',
  ADD_PRODUCT = '/vendor/add-product',
  COLLECTIONS = '/vendor/collections',
  VENDOR_PROFILE = '/vendor/profile',
  ALL_PRODUCTS = '/vendor/all-products',
  EDIT_PRODUCT = '/vendor/product/edit',
  ACCOUNT_SETUP = '/vendor/account-setup',
  ALL_TRANSACTIONS = '/vendor/transactions',
  REPORTS = '/vendor/reports',

  //STORE ROUTES
  MY_STORE = '/',
  MY_STORE_LOGIN = '/login',
  MY_STORE_SIGNUP = '/signup',
  MY_STORE_FORGOT_PASSWORD = '/forgot-password',
  MY_STORE_CHANGE_PASSWORD = '/recover-password',
  MY_STORE_CART = '/cart',
  MY_STORE_PRODUCTS = '/products',
  MY_STORE_SINGLE_PRODUCT = '/product/:slug/*',
  MY_STORE_PRODUCT_DETAIL = '/product',
  MY_STORE_CHECKOUT = '/checkout',
  MY_STORE_DYNAMIC_CHECKOUT = '/dynamic-checkout',
  MY_STORE_PROFILE = '/profile',
  MY_STORE_REQUEST_FUND = '/my-orders/:orderId/item/:itemId/request-return',
  MY_STORE_TRACK_RETURN = '/my-orders/track-return/:returnId',
  MY_STORE_TRACK = '/my-orders/track-return/',
  MY_STORE_MYORDERS = '/my-orders',
  MY_STORE_MYORDER_DETAILS = '/my-orders/:orderId/item/:itemId',
  MY_STORE_RETURN_SUCCESS = '/my-orders/return-success',
  MY_STORE_RETURN_POLICY = '/return-policy',
  MY_STORE_MESSAGES = '/messages',
  MY_STORE_CATERGORY_DETAILS = '/category/:category',
  MY_STORE_TERMS_CONDITION = '/terms-conditions',
  MY_STORE_SHIPPING_POLICY = '/shipping-policy',
}
