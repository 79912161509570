import Stack from "components/stack";
import Button from "../button";
import { TableFilterBox } from "../table/styles/table.styles";
import FilterSelect from "./multi-filter";
import { FilterProps } from "./type";


const Filter = ({
  hideFilter,
  pb,
  showBtn = false,
  tablefilterOptions,
  direction,
}: FilterProps) => {

  return (
    <>
      <TableFilterBox pb={pb} direction={direction} mobileDirection={"row"}>
        <Stack>
          {showBtn && (
            <Button height={"40px"} width={"130px"} onClick={() => { }}>
              Export
            </Button>
          )}
        </Stack>
        {!hideFilter && < FilterSelect options={tablefilterOptions} />}

      </TableFilterBox>
    </>
  );
};

export default Filter;
