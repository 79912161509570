import React from "react";

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="109"
      fill="none"
      viewBox="0 0 114 113"
    >
      <g filter="url(#filter0_b_1423_13560)">
        <circle cx="57" cy="56.5" r="56.5" fill="#fff"></circle>
      </g>
      <g filter="url(#filter1_b_1423_13560)">
        <circle cx="57" cy="56.5" r="54.677" fill="#E6FFEF"></circle>
      </g>
      <path
        fill="#15803D"
        d="M57 84c-14.912 0-27-12.088-27-27s12.088-27 27-27 27 12.088 27 27-12.088 27-27 27zm0-5.4a21.6 21.6 0 100-43.2 21.6 21.6 0 000 43.2zm-2.692-10.8L42.852 56.344l3.818-3.818 7.638 7.638L69.58 44.891l3.82 3.817L54.31 67.8z"
      ></path>
      <defs>
        <filter
          id="filter0_b_1423_13560"
          width="226"
          height="226"
          x="-56"
          y="-56.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="28.25"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1423_13560"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1423_13560"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_b_1423_13560"
          width="222.355"
          height="222.355"
          x="-54.177"
          y="-54.678"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="28.25"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1423_13560"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1423_13560"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default SuccessIcon