import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'

type Props = {
    position:'left' | 'right'
}

export const SearchWrapper = styled.div<Props>`
position: relative;
width:100%;
svg{
position: absolute;
${({position})=>position===`left`?`left: 10px;`:`right: 10px;`};
top: 10px;
}
input{
border: 0.5px solid ${Color.Neutral500};
border-radius: 6px;
height: 40px;
padding: 0 2.5rem;
width:100%;
outline:none;
box-sizing: border-box;
::placeholder{
    color:#DBDBDB;
    font-size:.9rem;
}
}


`