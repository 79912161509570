import React from 'react';
import { ArrowDownIcon, FilterIcon } from '../icons';
import { Color } from '../../assets/theme/colors';
import { ButtonContainer, Group, Filter } from './styles/button.style';
import { ButtonGroupProps, ButtonProps } from './type';
import Loader from 'components/loader';

const Button = ({ loading, loaderColor, children, ...restProps }: ButtonProps) => {
  return <ButtonContainer {...restProps}>
    {loading ? <Loader color={loaderColor} />
      : <>
        {children}
      </>
    }

  </ButtonContainer>;
};

export default Button;

Button.Group = function ButtonGroup({
  children,
  ...restProps
}: ButtonGroupProps) {
  return <Group {...restProps}>{children}</Group>;
};

Button.Filter = function ButtonFilter({ children, ...restProps }: ButtonProps) {
  return (
    <Filter {...restProps}>
      <FilterIcon />
      {children}
      <ArrowDownIcon color={Color.default} height={'7'} />
    </Filter>
  );
};
