
import {
  StylesConfig,
  CSSObjectWithLabel,
  ControlProps,
  GroupBase,
} from "react-select";
import { Color } from "../../../assets/theme/colors";

type selectStyleProps = {
  isFocused?: boolean;
};
type Coptions = {
};
export const selectStyles = (isBlack?: boolean, fullWidth?: boolean) => {
  return isBlack
    ? {
      control: (
        styles?: CSSObjectWithLabel,
        state?: ControlProps<Coptions, boolean, GroupBase<Coptions>>
      ) => ({
        ...styles,
        border: state?.isFocused
          ? `1px solid ${Color.Neutral500}`
          : `1px solid ${Color.Neutral500}`,
        height: 40,
        fontSize: '.9rem',
        borderRadius: 7,
        boxShadow: Color.Neutral500,
        "&:hover": {
          border: `1px solid ${Color.Neutral500} !important`,
        },
      }),

      option: (styles: StylesConfig<Coptions>, { isFocused }: selectStyleProps) => {
        return {
          ...styles,
          backgroundColor: isFocused ? `${Color.dark200}` : `${Color.default}`,
          color: isFocused ? `${Color.default}` : ``,
          borderBottom: `1px solid ${Color.Neutral300}`,
          "&:last-child": {
            border: "none",
          },
        };
      },
    } :
    {
      control: (
        styles?: CSSObjectWithLabel,
        state?: ControlProps<Coptions, boolean, GroupBase<Coptions>>
      ) => ({
        ...styles,
        border: state?.isFocused
          ? `1px solid ${Color.Neutral500}`
          : `1px solid ${Color.Neutral500}`,
        height: 40,
        fontSize: '.9rem',
        borderRadius: 7,
        boxShadow: Color.Neutral500,
        "&:hover": {
          border: `1px solid ${Color.Neutral500} !important`,
        },
      }),

      option: (styles: StylesConfig<Coptions>, { isFocused }: selectStyleProps) => {
        return {
          ...styles,
          backgroundColor: isFocused ? `${Color.DeelaaRed500}` : `${Color.default}`,
          color: isFocused ? `${Color.default}` : ``,
          borderBottom: `1px solid ${Color.Neutral300}`,
          "&:last-child": {
            border: "none",
          },
        };
      },
    }

}
export const colourStyles = (margin?: string, height?: number, noBorder?: boolean) => {
  return {
    control: (
      styles?: CSSObjectWithLabel,
      state?: ControlProps<Coptions, boolean, GroupBase<Coptions>>
    ) => ({
      ...styles,
      border: state?.isFocused
        ? `1px solid ${Color.Neutral500}`
        : `1px solid ${Color.Neutral500}`,
      height: height || 40,
      fontSize: '.9rem',
      borderRadius: 7,
      boxShadow: Color.Neutral500,
      "&:hover": {
        border: `1px solid ${Color.Neutral500} !important`,
      },
    }),

    option: (styles: CSSObjectWithLabel, { isFocused }: selectStyleProps) => {
      return {
        ...styles,
        backgroundColor: isFocused ? `${Color.DeelaaRed500}` : `${Color.default}`,
        color: isFocused ? `${Color.default}` : ``,
        borderBottom: noBorder ? "none" : `1px solid ${Color.Neutral300}`,
        margin: margin,
        "&:last-child": {
          border: "none",
        },
      };
    },
  };

}

