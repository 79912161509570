
import { Color } from 'assets/theme/colors';
import styled from 'styled-components'


type Props={
    isSelected?: boolean;
}

export const MultiSelectWrapper=styled.div`
position: absolute;
min-width: 200px;
min-height: 200px;
right: 30px;

`

export const SelectOptions = styled.div`

display:flex;
gap:20px;
flex-direction:column;
position:relative;
width:200px;
z-index:2;
position:absolute;
border-radius:7px;
font-size:.85rem;
min-height:auto;
background:${Color.default};
padding:1.5rem 1rem 1.5rem 1rem;
box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 10%);
animation: zoom-in-zoom-out .5s ease ;
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }

}



`

export const CheckBox = styled.div<Props>`
height:20px;
width:20px;
display:flex;
align-items:center;
justify-content:center;
border-radius: 4px;
border:1px solid ${({isSelected})=>isSelected ? Color.DeelaaRed500:'#ababab'};
background:${({isSelected})=>isSelected?Color.DeelaaRed500:''};
cursor: pointer;


`

export const  Option = styled.div`
display:flex;
gap:10px;
align-items:center;
&:hover{
  color:${Color.DeelaaRed500};
  font-weight:600;
  ${CheckBox}{
    border:1px solid ${Color.DeelaaRed500};
  }
}

`

export const InnerBox = styled.div`
background:${Color.DeelaaRed500};

`