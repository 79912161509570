import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'


type props={
    isChecked: boolean;
    backgroundColor?: string;
    borderColor?: string
}

export const CheckBoxWrapper= styled.div`
 display:flex;
 gap:5px; 
 margin:.5rem 0 0 0;

`
export const Check=styled.div<props>`
width:1.2rem; 
height:1.2rem;
border: ${({isChecked, borderColor})=>isChecked ?  `1.5px solid ${ borderColor || Color.DeelaaRed500}`: `1.5px solid ${Color.Neutral400}`};
border-radius: 4px;
background: ${({isChecked, backgroundColor})=>isChecked ? backgroundColor ||  Color.DeelaaRed500 : 'transparent'};
cursor:pointer;

`