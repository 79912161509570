import { Color } from 'assets/theme/colors';
import styled from 'styled-components/macro';

type Props = {
    bgColor?: string;
}

export const StatsCardWrapper = styled.div`
width:32%; 
height:150px; 
display:flex;
align-items:center;
padding:1rem 2.5rem;
background:${Color.default};
border-radius:7px;
@media(max-width:1200px){
width:48.8%; 
margin-bottom:1rem;  
}
@media(max-width:640px){
width:100%; 
height:100px; 
p{
font-size: .8rem;
}
}
`
export const CardIconBox = styled.div<Props>`
background-color: ${({bgColor})=>bgColor};
display:flex;
justify-content: center;
align-items:center;
height:50px; 
width:50px;
border-radius:50%;

`