import { string } from 'prop-types';
import styled from 'styled-components/macro'

type RichTextProps = {
    truncate: boolean;
    fontSize?: string;
    color?: string;
    width?: string;
}

export const RichTextWrapper = styled.div<RichTextProps>`
text-overflow:${({ truncate }) => truncate && 'ellipsis'};
overflow: ${({ truncate }) => truncate && 'hidden'};
white-space: ${({ truncate }) => truncate && 'nowrap'};
font-size:${({ fontSize }) => fontSize};
width: ${({ width }) => width || '100%'};
color:${({ color }) => color};
ul > li{
text-overflow:${({ truncate }) => truncate && 'ellipsis'};
overflow: ${({ truncate }) => truncate && 'hidden'};
white-space: ${({ truncate }) => truncate && 'nowrap'};


}




`