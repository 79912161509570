import React from 'react'
import { IconProps } from '../type';

function ArrowRight2({ width, height, color,className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "7"} 
      height={height ? height : "11"}
      fill="none"
      viewBox="0 0 7 11"
      className={className}
    >
      <path
        fill={color?color:'#AAB7C6'}
        d="M1.5 0a1 1 0 00-.71 1.71L4.1 5 .92 8.31a1 1 0 101.42 1.41l3.86-4a1 1 0 000-1.4l-4-4A1 1 0 001.5 0z"
      ></path>
    </svg>
  );
}

export default ArrowRight2;