import React from "react";
import { IconProps } from "../type";

const ArrowDownIcon=({width, height,color}:IconProps)=>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"10"}
      height={height ? height :"6"}
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        fill={color ? color :"#848F9F"}
        d="M8.717.513a.755.755 0 011.226.24.739.739 0 01-.163.813L5.532 5.729a.758.758 0 01-1.064 0L.22 1.566a.74.74 0 010-1.053.755.755 0 011.063 0l3.719 3.414L8.717.513z"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;