import React from "react";
import { IconProps } from "../type";

export const CloseIcon = ({
  onClick,
  width,
  height,
  color,
  className,
}: IconProps) => {
  return (
    <svg
      onClick={onClick}
      width={width ? width : "33"}
      height={height ? height : "33"}
      className={className}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9062 2.87305C9.10625 2.87305 2.90625 9.07305 2.90625 16.873C2.90625 24.673 9.10625 30.873 16.9062 30.873C24.7062 30.873 30.9062 24.673 30.9062 16.873C30.9062 9.07305 24.7062 2.87305 16.9062 2.87305ZM22.3062 23.873L16.9062 18.473L11.5063 23.873L9.90625 22.273L15.3062 16.873L9.90625 11.473L11.5063 9.87305L16.9062 15.273L22.3062 9.87305L23.9062 11.473L18.5063 16.873L23.9062 22.273L22.3062 23.873Z"
        fill="black"
      />
    </svg>

  );
};

export default CloseIcon;
