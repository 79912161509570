import { Action } from "../actions";
import {ActionTypes, CustomerState } from "../types/type";


export const customerReducer = (state:CustomerState, action:ActionTypes)=>{

    switch (action.type) {
        case Action.LOGIN:
            return {...state, user:action.payload,error:{}};
        case Action.REGISTER:
            return {...state, user:action.payload};
        case Action.VENDOR:
            return {...state, vendorDetails:action.payload};
        case Action.USERDATA:
            return {...state, userInfo:action.payload};
        case Action.LOGOUT :
              return {...state, user: null,error:{}};
        case Action.ERROR:
              return {...state, error:action.payload};
        default:
          return  state;
  }}