import React from "react";
import { IconProps } from "../type";

const SearchIcon=({width, height, color,className}:IconProps)=>{
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width :"20"}
    height={height ? height :"20"}
    fill="none"
    viewBox="0 0 20 20"
    className={className}
  >
    <path
      fill={color ? color :"#B4B4B5"}
      fillRule="evenodd"
      d="M9.167 3.334a5.833 5.833 0 100 11.666 5.833 5.833 0 000-11.666zm-7.5 5.833a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color ? color :"#B4B4B5"}
      fillRule="evenodd"
      d="M13.286 13.286a.833.833 0 011.178 0l3.625 3.625a.833.833 0 01-1.178 1.179l-3.625-3.625a.833.833 0 010-1.179z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default SearchIcon;

