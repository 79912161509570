import { Color } from 'assets/theme/colors';
import styled from 'styled-components/macro';

type Props = {
    md?: string;
    sm?: string;
    xs?: string;
    xxs?: string;
    flexDirection?: string;
    padding?: string;
    smPadding?:string;
    dividerWidth?: string;
    dividerColor?: string;
    justifyContent?: string;
    displaySm?: string;
}

export const ColumnDiv = styled.div<Props>`
display:flex;
width: ${({ md }) => md ? md : '100%'};
flex:0 0 auto;
justify-content:${({ justifyContent }) => justifyContent};
padding: ${({ padding }) => padding};
flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
hr{
    width: ${({ dividerWidth }) => dividerWidth || "100%"};
    margin: .5rem 0;
    border-top: 1px solid ${({ dividerColor }) => dividerColor || `${Color.deepGray}`};
}
@media(max-width:992px){
    width: ${({ md }) => md ? md : '100%'};
}
@media(max-width:768px){
    width: ${({ sm }) => sm ? sm : '100%'};

}
@media(max-width:640px){
    width: ${({ xs }) => xs ? xs : '100%'};
    padding: ${({ smPadding }) => smPadding}
}
@media(max-width:480px){
    display: ${({ displaySm }) => displaySm};
    h3{
        font-size:1.5rem;
    };
    div{
        font-size: 1rem;
    
    }
    
}
@media(max-width:360px){
    width: ${({ xxs }) => xxs};
}

`