import { createGlobalStyle } from "styled-components";
import { Color } from "../assets/theme/colors";
import "animate.css/animate.min.css";

export const GlobalStyle = createGlobalStyle`
  body{
  color:${Color.dark200};
  }
  h1,h2{
    padding: 0;
  }
  h3,h4{
    padding: 0;
    font-weight:600;
  }

  a {
    color: inherit;
    text-decoration: none;
  }


::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: ${Color.default};
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: ${Color.Neutral600};
  border-radius:30px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: ${Color.DeelaaRed500};
} */

.react-tel-input .form-control {
    padding-left: 18px !important;
    width: 85% !important;
    border: 1px solid ${Color.Neutral500};
    border-radius: 7px;
    height: 45px;
    outline: none;
    margin-left: 15% !important;
    @media (max-width: 992px) {
    margin-left: 13% !important;
    width: 87% !important;
  }

@media (max-width: 360px) {
  margin-left: 17% !important;
  width: 83% !important;
}

}
.react-tel-input .flag-dropdown {
border-radius:7px;
background-color:transparent;
border: 1px solid ${Color.Neutral500};
width: 12%;
@media (max-width: 360px) {
 width:15%;
}

}
.react-tel-input .selected-flag {
  @media (max-width: 480px) {
    padding: 0 0 0 5px !important;
}

}
.react-tel-input .selected-flag:hover{
  background-color:transparent !important;
}
.react-tel-input .flag-dropdown.open .selected-flag{
  background:transparent;
  border-radius: 5px;
}
.react-tel-input .flag-dropdown.open {
  background-color:transparent !important;
  border-radius: 5px;
}
.total{
  width: 7rem;
}
#nprogress .bar {
  background:${Color.DeelaaRed500} !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px ${Color.DeelaaRed500}, 0 0 5px ${Color.DeelaaRed500};
}

#nprogress .spinner-icon {
  border-top-color: ${Color.DeelaaRed500};
  border-left-color:${Color.DeelaaRed500};
}
.jodit-status-bar a.jodit-status-bar-link {
    display: none !important;
}
.jodit-container{
  min-height:350px !important;
  background:${Color.default} !important;
}
&::placeholder{
    color:${Color.Neutral300};
    font-size:.8rem !important;
}
.css-1s2u09g-control {
  min-height: 45px !important;
  border-color:${Color.Neutral500} !important;
  border-radius: 7px !important;
}
.select-placeholder{
    color:${Color.Neutral300} !important;
    font-size:.8rem ;
}
.jodit-placeholder {
  color:${Color.Neutral300} !important;
  font-size:.8rem !important;
  font-family:'Galano',sans-serif;
}
.react-multi-carousel-list{
  height:100%;
@media(max-width:1180px){
    height:auto;
}
}
.react-select__control{
  min-width:150px !important;
  min-height:45px !important
}

.react-select_zindex{
z-index:999 !important;
}

.react-datepicker__day--selected{
  background-color:${Color.DeelaaRed500} !important;
}
.react-datepicker__header {
    text-align: center ;
    background-color: #fff !important;
    border-bottom: 1px solid #fff !important;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.cursor{
  cursor:pointer
}
.react-datepicker {
    border: none !important;
    box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 10%);
    }
  .react-datepicker__day--keyboard-selected{
    background-color:${Color.DeelaaRed500} !important;
  }

  .tool-tips{
    /* box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 10%) !important; */
    border-radius:5px !important;
    background-color:${Color.default} !important;
    color:${Color.dark} !important;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1) !important;
    &::after{
    background:${Color.default} !important;
    }

  }
.__react_component_tooltip::after {
    width: 12px!important;
    height: 12px !important;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.change-delivery-link{
  background-color: transparent;
  border: none;
  margin-top:1rem;
  cursor: pointer;
  color: #0D63A2;
  font-weight: 600;
}
.required-asterick{
  color: ${Color.DeelaaRed900};
  margin-left: .5rem;
}
  .refund-tool-tips{
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1) !important;
    border-radius:8px !important;
    background-color:${Color.default} !important;
    color:${Color.Neutral600} !important;
    margin-left:1rem!important;
    width: 214px;
    height: 84px;
    font-size: 10px;
    line-height: 20px;
  }
  .react-select-container {
  touch-action: manipulation !important;
}
  .gImgResp{
    @media(max-width:640px){
        width: auto;
       height:150px !important;
       margin:3rem;
    }

}

.jodit-container:not(.jodit_inline) .jodit-workplace{max-height:300px !important}

@media (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

`