import React from "react";
import { IconProps } from "../type";

const DeleteIcon = ({ width, height, color, onClick }: IconProps) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "16"}
      height={height ? height : "20"}
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        stroke={color ? color : "black"}
        strokeWidth="1.2"
        d="M11.4633 2.26833L11.6292 2.6H12H15C15.2209 2.6 15.4 2.77909 15.4 3V4.4H0.6V3C0.6 2.77909 0.779086 2.6 1 2.6H4H4.37082L4.53666 2.26833L4.98387 1.3739C5.22102 0.899603 5.70579 0.6 6.23607 0.6H9.76393C10.2942 0.6 10.779 0.899603 11.0161 1.3739L11.4633 2.26833ZM3 19.4C2.2268 19.4 1.6 18.7732 1.6 18V6.6H14.4V18C14.4 18.7732 13.7732 19.4 13 19.4H3Z"
      ></path>
    </svg>
  );
}

export default DeleteIcon;
