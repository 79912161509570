import { Color } from 'assets/theme/colors';
import Button from 'components/button';
import { FallbackErrorIcon } from 'components/icons';
import Stack from 'components/stack';
import Text from 'components/text';
import { Path } from 'constants/route-paths';
import { useNavigate } from 'react-router-dom';

type ErrorBoundaryProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

export function ErrorFallback({ error }: ErrorBoundaryProps) {
  const navigate = useNavigate();
  const refreshPage = () => {
    location.reload();
  };
  return (
    <Stack
      height="100vh"
      alignItems="center"
      direction="column"
      justifyContent="center"
    >
      <Stack alignItems="center" justifyContents="center" padding="2rem">
        <FallbackErrorIcon />
        <Text as="h2">Something went wrong</Text>
        <Text as="p" padding=".5rem 0" align="center">
          Kindly contact the tech support team or retry
        </Text>
        <Button.Group gap="10px" align="center" margin="2rem 0">
          <Button width="150px" onClick={() => navigate(Path.MY_STORE)}>
            Back to Home
          </Button>
          <Button
            width="130px"
            onClick={refreshPage}
            variant={Color.default}
            color={Color.dark200}
          >
            Refresh
          </Button>
        </Button.Group>
      </Stack>
    </Stack>
  );
}
