
import { Action } from "../actions";
import { State, ActionTypes } from "../types/type";

export const rootReducer = (state: State, action: ActionTypes) => {
    switch (action.type) {
        case Action.LOGIN:
            return { ...state, user: action.payload, error: {} };
        case Action.REGISTER:
            return { ...state, user: action.payload, };
        case Action.VENDOR:
            return { ...state, vendorDetails: action.payload };
        case Action.VENDOR_BUSINESS:
            return { ...state, vendorBusiness: action.payload };
        case Action.VENDOR_STORE_DETAILS:
                return { ...state, vendorStoreDetails: action.payload };
        case Action.USERDATA:
            return { ...state, userInfo: action.payload };
        case Action.LOGOUT:
            return { ...state, user: null, error: {} };
        case Action.CLEAR_BUSINESS:
            return { ...state, vendorBusiness: null, };
        case Action.ACTIVESIDEBAR:
            return { ...state, show: action.payload };
        case Action.SELECT_PERIOD:
            return { ...state, selectedValue: action.payload };
        case Action.IS_TOGGLE:
            return { ...state, isCollapsed: !state.isCollapsed };
        case Action.SELECT_ALL_TABLE_ITEMS:
            return { ...state, isSelectAll: !state.isSelectAll };
        case Action.ERROR:
            return { ...state, error: action.payload };
        case Action.ADD_FILTER_QUERIES:
            return { ...state, filterQueries: [...state.filterQueries, { ...action.payload }] };
        case Action.ADD_PROD_FILTER_QUERIES:
            return { ...state, productFilterQueries: [{ ...action.payload }] };
        case Action.REMOVE_PROD_FILTER_QUERIES:
            return { ...state, productFilterQueries: state?.productFilterQueries.filter((p: any) => p.property !== action.payload) };
        case Action.REMOVE_FILTER_QUERIES:
            return { ...state, filterQueries: state?.filterQueries.filter((p: any) => p.id !== action.payload?.id) };
        case Action.CART_ITEMS:
            return { ...state, cartItems: action.payload };
        default:
            return state;
    }
}