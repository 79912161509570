import React from "react";
import { IconProps } from "../type";

function Icon({ width, height, color,className}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"15"}
      height={height ? height :"28"}
      className={className}
      fill="none"
      viewBox="0 0 15 28"
    >
      <path
        fill={color ? color :"#05516B"}
        d="M9.76 27.995v-2.598c0-.842-.005-1.68-.005-2.524V20.35c0-.843-.005-1.68 0-2.524.005-.833-.039-1.667.019-2.56h4.409c.22-1.673.437-3.289.658-5.011h-5.11c0-.505-.005-.922 0-1.334.01-.843-.024-1.686.053-2.524.081-.95.634-1.482 1.6-1.654.361-.064.731-.078 1.097-.083.663-.014 1.336 0 2-.005.154 0 .303-.032.519-.064-.024-1.482.034-2.932-.072-4.437C13.788.097 12.716.014 11.639 0c-.754-.01-1.528.01-2.264.144-2.197.403-3.755 1.579-4.52 3.654-.331.884-.466 1.806-.47 2.737-.005 1.018.01 2.037.004 3.056 0 .19-.028.38-.052.616-1.472.079-2.914-.032-4.337.074v4.97h4.36c.015.333.034.592.039.852l.048 11.165c0 .232.029.463.053.727 1.774-.004 3.462-.004 5.26-.004v.004z"
      ></path>
    </svg>
  );
}

export default Icon;
