import React from "react";

function WarningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="113"
      fill="none"
      viewBox="0 0 114 113"
    >
      <g filter="url(#filter0_b_1423_14492)">
        <circle cx="57" cy="56.5" r="56.5" fill="#fff"></circle>
      </g>
      <g filter="url(#filter1_b_1423_14492)">
        <circle cx="57" cy="56.5" r="54.677" fill="#FFF8F0"></circle>
      </g>
      <path
        fill="#FFA63E"
        d="M57 81c-13.807 0-25-11.192-25-25 0-13.807 11.193-25 25-25 13.808 0 25 11.193 25 25 0 13.808-11.192 25-25 25zm0-5a20 20 0 100-40 20 20 0 000 40zm-2.5-12.5h5v5h-5v-5zm0-20h5v15h-5v-15z"
      ></path>
      <defs>
        <filter
          id="filter0_b_1423_14492"
          width="226"
          height="226"
          x="-56"
          y="-56.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="28.25"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1423_14492"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1423_14492"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_b_1423_14492"
          width="222.355"
          height="222.355"
          x="-54.177"
          y="-54.678"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="28.25"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1423_14492"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1423_14492"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default WarningIcon;