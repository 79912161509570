import styled from "styled-components/macro";
import { Color } from "../../../assets/theme/colors";

type Props = {
  show?: boolean;
  isChecked?: boolean;
  pb?: string;
  direction?: string;
  mobileDirection?: string;
  hideBg?: boolean;
  headerBorderBottom?: string;
  borderColor?: string;
  height?: string;
  columnPadding?: string;
  headerFontSize?: string;
  isCart?: boolean
  headerColor?: string
  isVisible?: boolean

}

export const TableWrapper = styled.div`
  position: relative;
    overflow-x: scroll;
    overflow-y: hidden;

    scrollbar-width: none;
    -ms-overflow-style: none;
   ::-webkit-scrollbar {
    width: 0;
    height: 0;
     }

`;
export const DataTable = styled.table<Props>`
  width: 100%;
  border-collapse: collapse;
  thead {
    background:${({ hideBg }) => !hideBg && "#f4f4f4"};
    color: #373737;
    height: 65px;
    tr {
      text-align: left;
      font-size:0.8rem;
      th {
        padding: 1rem;
        font-weight: 600;
        font-size:14px;
        white-space: nowrap;
        color:${({ headerColor }) => headerColor};
        font-size:${({ headerFontSize }) => headerFontSize};
        border-bottom:${({ headerBorderBottom }) => headerBorderBottom};
      }
      td {
        padding: 1rem;
        font-weight: 600;
        white-space: nowrap;

        svg{
          cursor: pointer;
        }
      }
    }
  }
  tbody{

    tr {
    border-bottom: 1px solid ${({ borderColor }) => borderColor || Color.Neutral300};
    height: ${({ height }) => height || "65px"};
    color: #373737;
    &:last-child {
      border-bottom: ${({ isCart, borderColor }) => isCart ? 'none ' : `1px solid ${borderColor || Color.Neutral300}`};

    }

    td {
      white-space: nowrap;
      font-size: 1rem;
      padding:  ${({ columnPadding }) => columnPadding || ".5rem 1rem"};
      max-width: 200px;
      cursor: pointer;
      .truncate{
      overflow: hidden;
      text-overflow: ellipsis;
      }
      .live-product{
          display:flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height:25px;
          background:${Color.Green300};
          border-radius:20px;
          font-size:.9rem;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.dark};
            margin-right:.5rem;
          }
        }
        .fulfilled {
          display:flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height:25px;
          background:${Color.Green300};
          border-radius:20px;
          font-size:.9rem;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.dark};
            margin-right:.5rem;
          }
        }
        .draft-product{
          display:flex;
          align-items: center;
          justify-content: center;
          width:70px;
          height:25px;
          font-size:.9rem;
          background:${Color.Neutral100};
          border-radius:20px;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.dark};
            margin-right:.5rem;
          }
        }
         .paymentprocessing{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          padding: 0 0.5rem;
          background:${Color.Processing100};
          color:${Color.Processing};
          border-radius:20px;
          max-width: 165px;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.Processing};
            margin-right:.5rem;
          }
        }
        .paymentfailed{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          padding: 0 0.5rem;
          background:${Color.DeelaaRed200};
          color:${Color.DeelaaRed500};
          border-radius:20px;
          max-width: 135px;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.DeelaaRed500};
            margin-right:.5rem;
          }
        }
        .processing{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          padding: 0 0.5rem;
          background:${Color.Processing100};
          color:${Color.Processing};
          border-radius:20px;
          max-width: 105px;
          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.Processing};
            margin-right:.5rem;
          }
        }
        .failed{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          min-width: 90px;
          padding: 0 0.5rem;
          background:${Color.DeelaaRed200};
          color:${Color.DeelaaRed500};
          border-radius:20px;
          max-width: 80px;

          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.DeelaaRed500};
            margin-right:.5rem;
          }

        }
        .paid{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          width: auto;
          padding: 0 0.5rem;
          background:${Color.Success100};
          color:${Color.Success};
          border-radius:20px;
          max-width: 70px;

          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.Success};
            margin-right:.5rem;
          }

        };
        .outfordelivery{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          width: auto;
          padding: 0 0.5rem;
          background:${Color.Success100};
          color:${Color.Success};
          border-radius:20px;
          max-width: 140px;

          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.Success};
            margin-right:.5rem;
          }

        };
        .delivered{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          width: auto;
          padding: 0 0.5rem;
          background:${Color.Success100};
          color:${Color.Success};
          border-radius:20px;
          max-width: 100px;

          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.Success};
            margin-right:.5rem;
          }

        };
        .readyForPickup{
          display:flex;
          align-items: center;
          height:25px;
          font-size:.8rem;
          width: auto;
          padding: 0 0.5rem;
          background:${Color.Blue100};
          color:${Color.Blue};
          border-radius:20px;
          max-width: 146px;

          &:before{
            content: "";
            height:6px;
            width:6px;
            border-radius:50%;
            background:${Color.Blue};
            margin-right:.5rem;
          }
        }
        .img-box{
          width:40px;
          height:40px;
          img{
        width:40px;
        height:40px;
        object-fit:'cover';
        border-radius:5px;
        }

        }

        svg{
          cursor: pointer;
        }
    }

    &:hover{
      background:${Color.Neutral50};
    }

  }
  }
`;

export const TableFilterBox = styled.div<Props>`
display:flex;
justify-content:space-between;
align-items:center;
flex-direction: ${({ direction }) => direction};
gap:.5rem;
padding-bottom: ${({ pb }) => pb ? pb : '1rem'};
@media(max-width:480px){
    flex-direction: ${({ mobileDirection }) => mobileDirection ? mobileDirection : 'column'};
    gap: 10px;
}
`

export const TableCheckBox = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ isChecked }) => isChecked ? Color.DeelaaRed500 : '#ababab'};
  background:${({ isChecked }) => isChecked ? Color.DeelaaRed500 : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
`;

export const TableFilterInner = styled.div`
padding:relative;
input{
  width:350px;
}

@media(max-width:640px){
  width: 100%;
  input{
  width:100%;
}
}

`
export const PopUpWrapper = styled.div<Props>`
font-size:.85rem;
right:1rem;
position:absolute;
width:160px;
opacity: ${({ show }) => show ? '1' : '0'};
max-height:${({ show }) => show ? '400px' : '0'};
background:${Color.default};
overflow: hidden;
z-index:1;
bottom: 30px;
border-radius: 10px;
border:1px solid ${Color.Neutral500};
transition: 0.2s ease;
box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 10%);
`
export const PopUpStack = styled.div`
display: flex;
flex-direction: column;
button{
   padding:.8rem 1rem;
  cursor: pointer;
  background:transparent;
  outline: none;
  border:none ;
  text-align:left ;
  color:${Color.dark};
  font-weight:500;
text-transform:capitalize;
&:hover{
    color:${Color.DeelaaRed500};
    background:${Color.Neutral50};
  }
}

`
export const SelectedItem = styled.div<Props>`
position: relative;
width:300px;
opacity: ${({ isVisible }) => isVisible ? '1' : '0'};
transition: 1s ease linear;
`
export const ItemBox = styled.div`
display: flex;
border:1px solid ${Color.Neutral500};
height:40px;
 width:100%;
 margin-bottom:10px;
`
export const InnerBox = styled.div`

 display:flex;padding:0 1rem;
 align-items: center;
 gap:15px;
 &:first-of-type{
  border-right: 1px solid ${Color.Neutral500};
 width:70%;
 }
 &:last-of-type{
cursor:pointer;
 }
`

export const SelectedItemAction = styled.div`
position: absolute;
right: 0;
z-index:999;
background:${Color.default};
box-shadow: 1px 1px 2px 2px rgb(0 0 0 / 10%);
animation: zoom-in-zoom-out .5s ease ;
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }

}
`


