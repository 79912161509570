import React from "react";
import { IconProps } from "./../type.d";

function Icon({ width, height, color }: IconProps) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width:"19"}
    height={height ? height :"19"}
    fill="none"
    viewBox="0 0 19 19"
  >
    <path
      fill={color ? color :"#1C1C1C"}
      d="M16.964 0H2.036A2.036 2.036 0 000 2.036v14.928A2.036 2.036 0 002.036 19h5.82v-6.46H5.186V9.5h2.672V7.183c0-2.636 1.569-4.092 3.972-4.092 1.151 0 2.355.205 2.355.205v2.587h-1.326c-1.307 0-1.715.811-1.715 1.643V9.5h2.917l-.466 3.04h-2.45V19h5.82A2.036 2.036 0 0019 16.964V2.036A2.036 2.036 0 0016.964 0z"
    ></path>
  </svg>
    );
}

export default Icon;
