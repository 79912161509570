import ReactPaginate from 'react-paginate'
import { ArrowRight2, } from 'components/icons'

import { PaginationWrapper } from './styles/pagination.styles'
import { PaginationProps } from './type'


const Pagination = ({ data, setPageNumber, loading = false, isStore = false }: PaginationProps) => {
  const handlePageClick = ({ selected }: { selected: number }) => {
    setPageNumber(selected)
  }

  return (
    <><PaginationWrapper isStore={isStore}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowRight2 />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={data?.totalPageCount ?? 1}
        previousLabel={<ArrowRight2 />}
        containerClassName={'paginate'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        activeClassName={'active-page'}
        nextLinkClassName={'next-btn'}
        previousLinkClassName={'previous-btn'}
        disabledClassName={'pagination-disabled'}
      />
    </PaginationWrapper>

    </>
  );
};

export default Pagination;