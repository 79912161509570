import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Provider from './context';
import { Toaster } from 'react-hot-toast';
import Layout from './components/layout';
import { GlobalStyle } from './styles/global-styles';
import { QueryClient, QueryClientProvider } from 'react-query';

// import { ReactQueryDevtools } from "react-query/devtools";
import './styles/global.css';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <Layout>
            <App />
            <GlobalStyle />
            <Toaster />
          </Layout>
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'}/> */}
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
