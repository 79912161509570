import { Navigate, useLocation } from 'react-router-dom'
import { Path } from 'constants/route-paths'


const NotFound = ({ user }: { user: unknown }) => {

  if (!user) {
    <Navigate to={Path.MY_STORE_LOGIN} replace />
  }

  return <Navigate to={Path.MY_STORE_PRODUCTS} replace />
}

export default NotFound