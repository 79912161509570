import styled from 'styled-components'
import { Color } from '../../../assets/theme/colors'


type Props ={
    borderColor?: string;
    color?:string;
    isPro?:Boolean;
}

export const TableWrapper =styled.div`
display:flex; 
gap:3rem; 
width:100%;
flex-wrap:wrap;
justify-content:center; 
padding-bottom:5rem;

`
export const Table =styled.div<Props>`
padding:2rem 1rem;
border: ${({borderColor})=>borderColor ?`3px solid ${borderColor}`:'3px solid #e1e1e161'};
border-radius:10px; 
width: 100%; 
max-width:350px;
@media(max-width:480px){
max-width:300px;
}
`

export const TableHeader = styled.div`
display: flex;
justify-content:center;
flex-direction: column;
align-items:center;
padding:1rem 0 ;
h1{
    color:${Color.default};
}
span{
font-weight:400;
font-size:.8rem;
}
/* .currency{
font-family:sans-serif;
} */
`
export const TableFooter= styled.div<Props>`
color: ${Color.Neutral500};
display:flex; 
justify-content:center;
border-top: ${({borderColor})=>borderColor ?`1px solid ${borderColor}`:`1px solid ${Color.Neutral700}`}; 
min-height:120px;
align-items:center;

`

export const PriceList = styled.div<Props>`
color: ${({isPro})=>isPro ? Color.Neutral700 : Color.default};
display:flex;
padding:0 2rem;
border-top: ${({borderColor})=>borderColor ?`1px solid ${borderColor}`:`1px solid ${Color.Neutral700}`};
gap:.5rem; 
min-height:60px; 
align-items:center;


`

export const Box = styled.div`
width:25px;    
display:flex;
justify-content:center;

`