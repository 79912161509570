import React from 'react'
import { TextStyle } from './styles/text.style'
import { TextProps } from './type'


const Text = <E extends React.ElementType = 'div'>({ textDecoration, hideSmall, overflow, whiteSpace, textOverflow, color, weight, smsize, width, align, margin, padding, bgColor, size, opacity, cursor, children, as, textTransform, position, bottom }: TextProps<E>) => {
  let Component = as || 'div'
  return (
    <TextStyle textDecoration={textDecoration} textOverflow={textOverflow} overflow={overflow} whiteSpace={whiteSpace} hideSmall={hideSmall} bottom={bottom} position={position} smsize={smsize} margin={margin} opacity={opacity} color={color} width={width} cursor={cursor} align={align} size={size} textTransform={textTransform} bgColor={bgColor} weight={weight} padding={padding}>
      <Component>{children}</Component>
    </TextStyle>
  )
}

export default Text