
import img1 from '../assets/images/listing/1.png'
import img2 from '../assets/images/listing/2.png'
import img3 from '../assets/images/listing/3.png'
import img4 from '../assets/images/listing/4.png'
import img5 from '../assets/images/listing/5.png'
import img6 from '../assets/images/listing/6.png'
import img7 from '../assets/images/listing/7.png'
import img8 from '../assets/images/listing/8.png'



import catImg1 from 'assets/images/cat1.webp'
import catImg2 from 'assets/images/cat2.webp'

export const allProducts = [
    {
        id: '1',
        image: '../assets/images/product-1.jpg',
        productName: 'Nike Retro Tracksuit',
        status: 'Draft',
        price: '₦5,200',
        stock: '35'
    },
    {
        id: '2',
        image: '/assets/images/product-2.jpg',
        productName: 'Nike Retro Tracksuit',
        status: 'Live',
        price: '₦2,500',
        stock: '125'
    },
    {
        id: '3',
        image: '/assets/images/product-1.jpg',
        productName: 'Nike Retro Tracksuit',
        status: 'Live',
        price: '₦1,100',
        stock: '115'
    },
    {
        id: '4',
        image: '/assets/images/product-2.jpg',
        productName: 'Nike Retro Tracksuit',
        status: 'Live',
        price: '₦10,500',
        stock: '15'
    }
]

export const refunds = [
    {
        id: '#123456780',
        productName: 'Nike Retro Tracksuit',
        status: 'Draft',
        requestDate: 'Oct 27, 2021. 12:34pm',
        requestType: 'Return & refund'
    },
    {
        id: '#123456780',
        productName: 'Nike Retro Tracksuit',
        status: 'Draft',
        requestDate: 'Oct 27, 2021. 12:34pm',
        requestType: 'Return only'
    },
]


export const selectData = [
    { name: 'Electronics' },
    { name: ' Fashions' },
    { name: ' Furniture & Home' },
    { name: 'Gift Cards & Coupons ' },
    { name: `Health & Beauty` },
    { name: ' Kids' },
    { name: ' Mobile Phones & Tablets' },
    { name: ' Real Estate' },
    { name: ' Repair & Construction' },
    { name: ' Sports Equipment  ' },
]




export const allOrdersHeaders = ['Order ID ', 'Customer Name', 'Date', 'Status', 'Delivery Method', 'Total Amount']

export const OrderedProductDetails = {

    products: [
        {
            id: '1',
            image: '/assets/images/1.png',
            name: 'Nike Woven Tracksuit X Force',
            price: '35,500.00',
            qty: '1',
            total: '35,500.00',
            variant: 'Black  |   XL',
            sku: 'NK346820'
        },
        {
            id: '2',
            image: '/assets/images/2.png',
            name: 'Nike Revolution 6 Next Nature',
            price: '35,500.00',
            qty: '2',
            total: '70,100.00',
            variant: 'Lemon Neon  |   UK10',
            sku: 'NK346820'
        },
        {
            id: '3',
            image: '/assets/images/3.png',
            name: 'Stella McCartney Nylon Bucket',
            price: '35,500.00',
            qty: '1',
            total: '35,500.00',
            variant: 'Black  |   XL',
            sku: 'NK346820'
        }

    ],
    total_amount: '97,000.00',
    delivery_fee: '1,500.00',
    sub_total: '95,500.00',
    tax: '0,00',
    order_note: 'Please ship all items together and call before delivery.'
}

export const multiSelectData = [
    {
        id: 1,
        name: 'Ready for Pickup'
    },
    {
        id: 2,
        name: ' Out for Delivery'
    },
    {
        id: 3,
        name: ' Delivered'
    },
    {
        id: 4,
        name: ' Failed'
    },

]

//TODO REFACTOR
export const ordersFilters = [
    {
        id: 1,
        property: "status",
        op: "EQ",
        value: "PROCESSING"
    },
    {
        id: 2,
        property: "status",
        op: "EQ",
        value: "READY_FOR_PICKUP"
    },
    {
        id: 4,
        property: "status",
        op: "EQ",
        value: "OUT_FOR_DELIVERY"
    },
    {
        id: 5,
        property: "status",
        op: "EQ",
        value: "FAILED"
    },
    {
        id: 6,
        property: "status",
        op: "EQ",
        value: "DELIVERED"
    },





]
//TODO REFACTOR

export const productsfilters = [
    {
        id: 8,
        property: "status",
        op: "eq",
        value: "LIVE"
    },
    {
        id: 9,
        property: "status",
        op: "eq",
        value: "DRAFT"
    },
]




export const Products = [
    {
        id: '1',
        image: img1,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'Football Shirt',
        amount: '59950',
        rating: '4.5'
    },
    {
        id: '2',
        image: img2,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'football',
        amount: '59950',
        rating: '4.5'
    },
    {
        id: '3',
        image: img1,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'Football Shirt',
        amount: '59950',
        rating: '4.5'
    },
    {
        id: '4',
        image: img2,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'football',
        amount: '59950',
        rating: '4.5'
    },
    {
        id: '5',
        image: img2,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'football',
        amount: '59950',
        rating: '4.5'
    }
]


export const categories = [
    {
        id: '1',
        image: img3,
        name: 'MEN',
        value: 'category 1',

    },
    {
        id: '2',
        image: img4,
        name: 'WOMEN',
        value: 'category 2',
    },

    {
        id: '3',
        image: img5,
        name: 'KIDS',
        value: 'category 3'

    },

]

export const singleCategory = [
    {
        id: '1',
        image: catImg1,
        name: 'FRESH OUT',

    },
    {
        id: '2',
        image: catImg2,
        name: 'HOT SALES',

    },

]

export const subCategories = [
    {
        id: '1',
        name: 'Accessories',
    },
    {
        id: '2',
        name: 'Hats',
    },
    {
        id: '3',
        name: 'Shoes',
    },
    {
        id: '2',
        name: 'Pants',
    },

    {
        id: '3',
        name: 'Tops',
    },

]
export const brands = [
]
export const styles = [
    {
        id: '1',
        name: 'Fitted',
    },
    {
        id: '2',
        name: 'Skinny',
    },
    {
        id: '3',
        name: 'Short sleeve',
    },
    {
        id: '4',
        name: 'Long sleeve',
    },

    {
        id: '5',
        name: 'Slim',
    },
]
export const colorCodes = [
    {
        id: '1',
        name: '#000000',
        border: 'none'
    },
    {
        id: '2',
        name: '#ffffff',
        border: '#878787'
    },
    {
        id: '3',
        name: '#E50A11',
        border: 'none'
    },
    {
        id: '4',
        name: '#FCB706',
        border: 'none'
    },
    {
        id: '5',
        name: '#FFF505',
        border: 'none'
    },
    {
        id: '6',
        name: '#239F04',
        border: 'none'
    },
    {
        id: '7',
        name: '#0078E7',
        border: 'none'
    },
    {
        id: '8',
        name: '#800CDC',
        border: 'none'
    },
    {
        id: '9',
        name: '#EA1DFC',
        border: 'none'
    },
    {
        id: '10',
        name: '#BBBBBB',
        border: 'none'
    },
]

export const kits = [
    {
        id: '1',
        name: 'Home',
    },
    {
        id: '2',
        name: 'Away',
    },



]
export const sizes = [
    {
        id: '1',
        name: 'XS',
    },
    {
        id: '2',
        name: 'S',
    },
    {
        id: '3',
        name: 'M',
    },
    {
        id: '4',
        name: 'L',
    },
    {
        id: '5',
        name: 'XL',
    },


]
export const topSales = [
    {
        id: '1',
        image: img6,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'Football Shirt',
        amount: '59950',
        rating: '4.5',
        discount: '₦79,950',
        percent: '-20%',
    },
    {
        id: '2',
        image: img8,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'Football Shirt',
        amount: '59950',
        rating: '4.5',
        discount: '₦79,950',
        percent: '-45%',
    },
    {
        id: '3',
        image: img7,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'Football Shirt',
        amount: '59950',
        rating: '4.5',
        discount: '₦79,950',
        percent: '-15%',
    },
    {
        id: '4',
        image: img6,
        name: 'Liverpool F.C. 21/22 Home Kit Goalkeeper',
        description: 'Football Shirt',
        amount: '59950',
        rating: '4.5',
        discount: '₦50,950',
        percent: '-49%',
    },
]

export const cartSummary = [{
    label: "Subtotal",
    value: "₦106,000.00",
},
{
    label: "Delivery",
    value: "₦2,000.00"
},
{
    label: "Total",
    value: "₦108,000.00"
},
]

export const storeProducts = [

    {
        orderId: 'Order ID #436593',
        image: img8,
        name: 'Nike Revolution 6 Next Nature',
        description: 'Black  |   XL',
        amount: '59950',
        deliveryStatus: "Pending",
        quantity: 2,
        date: "30-08-2022"
    },

    {
        orderId: 'Order ID #436593',
        image: img8,
        name: 'Nike Revolution 6 Next Nature',
        description: 'Black  |   XL',
        amount: '59950',
        deliveryStatus: "Pending",
        quantity: 2,
        date: "30-08-2022"
    },

    {
        orderId: 'Order ID #436593',
        image: img8,
        name: 'Nike Revolution 6 Next Nature',
        description: 'Black  |   XL',
        amount: '59950',
        deliveryStatus: "Pending",
        quantity: 2,
        date: "30-08-2022"
    },

    {
        orderId: 'Order ID #436593',
        image: img8,
        name: 'Nike Revolution 6 Next Nature',
        description: 'Black  |   XL',
        amount: '59950',
        deliveryStatus: "Pending",
        quantity: 2,
        date: "30-08-2022"
    },
]
export const myOrders = [
    {
        orderId: 'Order ID #436593',
        image: img8,
        name: 'Nike Revolution 6 Next Nature',
        description: 'Black  |   XL',
        amount: '59950',
        deliveryStatus: "Pending",
        quantity: 2,
        date: "30-08-2022"
    },

]

export const cartItems = [
    {
        id: '1',
        image: img8,
        productName: 'Nike Revolution 6 Next Nature',
        description: 'Black  |   XL',
        price: '59950',
        rating: '4.5',
        discount: '₦79,950',
        totalPrice: "59950",
        percent: '-15%',
        quantity: 1,
    },


]
export const address = {
    fullName: "Peter Atiku-Tinubu",
    address: "17b, David Robinson Avenue Victoria Island Lagos",
    phoneNumber: "08185280421",
}

export const messages = [
    {
        id: "1",
        user: 'Sportplace',
        title: 'Amazing Discounts this month',
        message: '  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh ...',
    },
    {
        id: "2",
        user: 'Deelaa.com',
        title: 'Ship Items for Free! Up to 80% Discount',
        message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh ...',
    }
]

